@import "src/theme/colors.scss";

$borderColor: #c4c4c4;
$shadowColor: #dbe0eecc;
$borderRadius: 8px;
$border: 1px;

$tipSize: 20px;
$minTipShift: 3 * $borderRadius;

$tooltipOffset: $tipSize;
$TipOffset: $minTipShift;

.root {
  position: fixed;
  opacity: 1;
  z-index: 1;
  transform: scale(1);
  transition: opacity 0.2s ease-in-out;
  user-select: none;

  background: white;
  border-radius: $borderRadius;
  box-shadow: 0px 8px 20px $shadowColor;
  border: $border solid $borderColor;

  
  &.top,
  &.bottom {
    min-width: 1.4 * $tipSize + 2 * $borderRadius;
  }
  
  &.left,
  &.right {
    min-height: 1.4 * $tipSize + 2 * $borderRadius;
  }
}

.body {
  padding: 8px;
  color: black;
  font-size: 14px;
  pointer-events: all;
  max-width: 500px;
}

.top {
  transform: translate(
    var(--container-offset, -50%),
    calc(-100% - #{$tooltipOffset})
  );
}

.right {
  transform: translate($tooltipOffset, var(--container-offset, -50%));
}

.bottom {
  transform: translate(var(--container-offset, -50%), $tooltipOffset);
}

.left {
  transform: translate(
    calc(-100% - #{$tooltipOffset}),
    var(--container-offset, -50%)
  );
}

// tip ===================

.tip {
  position: absolute;
  overflow: hidden;
  text-align: initial;
  &::after {
    content: "";
    transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
    position: absolute;
    height: $tipSize - 2 * $border;
    width: $tipSize - 2 * $border;
    background: white;
    border: $border solid $borderColor;
  }
}

:is(.top, .bottom) .tip {
  left: var(--tip-offset, -50%);
  height: $tipSize * 0.7;
  width: $tipSize * 1.4;
  transform: translateX(-50%);
  &::after {
    left: 50%;
    border-radius: $borderRadius 0;
  }
}

:is(.right, .left) .tip {
  top: var(--tip-offset, -50%);
  width: $tipSize * 0.7;
  height: $tipSize * 1.4;
  transform: translateY(-50%);
  &::after {
    top: 50%;
    border-radius: 0 $borderRadius;
  }
}

.top .tip {
  top: 100%;
  &::after {
    top: 0;
  }
}

.right .tip {
  right: 100%;
  &::after {
    left: 100%;
  }
}

.bottom .tip {
  bottom: 100%;
  &::after {
    top: 100%;
  }
}

.left .tip {
  left: 100%;
  &::after {
    left: 0;
  }
}

// tooltip justification =======

.center {
  --container-offset: -50%; // center
  --tip-offset: 50%; // center
}

.start {
  --container-offset: #{-$TipOffset}; // start
  --tip-offset: #{$TipOffset}; // start
}

.end {
  --container-offset: calc(-100% + #{$TipOffset}); // end
  --tip-offset: calc(100% - #{$TipOffset}); // end
}

// =============================
