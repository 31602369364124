.root {
}

.wrapper {
  display: grid;
  grid-template-columns: 5fr 4fr 5fr;
  margin-top: 20px;
  row-gap: 10px;
  column-gap: 10px;
}
