@import "@root/theme/colors.scss";

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 3.4 1;
  z-index: 0;
  overflow: visible;
  padding: 0;
}

.body {
  display: flex;
  overflow: hidden;
  margin-top: 10px;
  padding: 20px;
  padding-top: 0;
  border-radius: 10px 10px 0 0;
  z-index: -1;
}

.sticky {
  position: initial;
  z-index: 0;
  &::after {
    content: "";
    position: absolute;
    left: -25px;
    top: -20px;
    width: calc(100% + 50px);
    height: 30px;
    z-index: -1;
    background: linear-gradient(
      transparent,
      $pageBg 30%,
      $pageBg 70%,
      transparent
    );
  }
}
