@import '@root/theme/utils.scss';

.root {
  display: flex;
  flex-direction: column;
  gap: $p20;
  max-width: 530px;
  margin-block: 60px;
  margin-inline: auto;
}

.header {
  display: flex;
  flex-direction: column;
  gap: $p16;
  text-align: center;
}

.body {
  width: 350px;
  margin-inline: auto;
}

.row {
  position: relative;
}

.rowControl {
  position: absolute;
  display: flex;
  top: 19px;
  right: 0;
  transform: translateX(100%);
  padding-inline: 5px;
}