
.root {
  position: relative;
  cursor: pointer;
  &::after {
    content: '';
    width: 40px;
    height: 40px;
    border: 2px solid var(--main-color, #FF7F57);
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 50%;
    transition: opacity .2s ease-in;
  }
}

.root.focused::after {
  opacity: .3;
}

.badge {
  font-size: 12px;
  height: 32px;
  width: 32px;
  line-height: 32px;
  text-align: center;
  color: white;
  background: var(--main-color, #FF7F57);
  border-radius: 50%;
  user-select: none;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  min-width: 140px ;
  transition: opacity .2s ease-in-out;
  & span {
    color: #17899E;
  }
}
