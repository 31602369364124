.root
  display: flex
  padding: 40px 0
  height: max-content
  width: 100%
  max-width: 1400px
  overflow-x: auto

.container
  display: flex
  flex-direction: column
  gap: 32px
  width: 100%
  overflow-x: auto
