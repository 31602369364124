@import "@root/theme/colors.scss";

.root {
  height: 1px;
  width: 100%;
  background: #2121211a;
  border: none;
  margin: 0;
  margin-block: 8px;
}

.noLine {
  height: 0;
}
