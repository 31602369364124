@import "@root/theme/utils.scss";


.noFoundContainer,
.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: $p24;
  width: 700px;
  height: 100%;
}

.noFoundContainer {
  justify-content: center;
}