@import "@root/theme/utils.scss";

.root {
  padding: 25px 30px 30px 30px;
}

.title {
  margin-bottom: 5px;
}

.container {
  display: flex;
  margin-top: 19px;
  gap: $p24;
  &>* {
    flex: 0 1 230px
  }
}
