@import "@root/theme/utils.scss"

.root
  display: flex
  flex-direction: column
  height: min(620px, calc(100vh - 200px))
  gap: $p32

.header
  display: flex
  flex-direction: column
  gap: $p08
  width: 310px

.body
  flex: 1 1 100%
  overflow: auto

.list
  display: flex
  height: 140px
  gap: $p32

.card
  display: flex
  flex-direction: column
  align-items: center
  flex: 0 1 100%
  gap: $p16
  text-align: center
  position: relative

  .icon
    font-size: $p45
    color: $primary100Color

.info
  position: absolute
  top: $p10
  right: $p10

.bubble ul
  padding-left: $p24
  margin-block: 0 !important

.bubble li
  margin-block: $p04
