@import "src/theme/colors.scss";

.root {
  position: relative;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
}

.relative {
  position: absolute;
}

.root.relative {
  position: relative;
}

