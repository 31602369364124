@import "@root/theme/utils.scss";

.root {
  display: grid;
  grid-template-columns: 220px 1fr;
}

.content {
  margin-inline: $p24;
  width: auto;
}

.menuContainer {
  display: flex;
  flex-direction: column;
  &:after {
    content: '';
    box-sizing: border-box;
    width: 100%;
    flex: 1 1;
    border-right: 2px solid $black20Color;
  }
}
