@import "@root/theme/utils.scss";


.row,
.header {
  display: grid;
  align-items: center;
  grid-template-columns: $p24 auto 100px;
  padding-inline: $p12;
  gap: $p16;
}

.header {
  min-height: $p36;
}

.row {
  padding-block: $p03;
  min-height: $p40;
}

.container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: inherit;
  & > * {
    flex: 1 1;
    overflow: hidden;
  }
}

.active {
  background-color: $primary20Color;
}

.headerColumn {
  gap: $p06;
  display: flex;
  user-select: none;
  font-weight: 600;
  cursor: pointer;
}