.root {
  padding: 24px;
  margin-top: 20px;
  z-index: 0;
}

.section {
  margin: 5px 0;
}

.footer {
  height: 74px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
