@import "@root/theme/colors.scss";

.root {
  padding: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 24px;
  padding-inline: 20px;
  min-height: 88px;
  box-sizing: border-box;

  & > * {
    flex: 1 1 auto;
  }
}

.control {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}

.collapseArrow {
  padding: 10px;
  margin: -10px;
}

.datePicker {
  z-index: 1;
  max-width: 300px;
}

.chartContainer {
  box-sizing: border-box;
  padding-inline: 24px;
  padding-bottom: 24px;
  height: 262px;
}

.chart {
  padding: 0;
  box-shadow: none;
}
