.root {
  display: flex;
  box-sizing: border-box;
  overflow-x: auto;
  padding-bottom: 1em;
}

.loader {
  margin-left: 70px;
}
