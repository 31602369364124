@import "@root/theme/utils.scss";

$sidePadding: 10px;

.keywordContainer {
  overflow-y: auto;
  flex: 1 1;
  @include thinScrollbar('&');
}

.keywordList {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  text-align: center;
  padding: 0 $sidePadding 20px $sidePadding;
  padding-bottom: 10px;
  background-color: $lightTextColor;
  & > * {
    border-bottom: 1px solid $secondaryDarkColor;
  }
  & > *:last-child {
    border: none;
  }
}

.labelsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 40px;
  background: white;
}
