.root {
  width: 100%;
  padding: 40px 0;
  height: max-content;
}

.body {
  padding: 16px 0
}

.tableBody {
  display: flex;
  flex-direction: column;
  gap: 8px
}