@import "@root/theme/utils.scss";

$animationDuration: .2s;
$blurBgColor: rgba(128, 128, 128, 0.333);
$transparentBgColor: rgba(128, 128, 128, 0);

.root {
  position: fixed;
  border: none;
  inset: 0;
  margin: 0;
  max-height: var(--global-total-height, 100vh);
  z-index: $sidebarZIndex;
}

.root.visible {
  animation: blurBackground $animationDuration ease-out;
  background: $blurBgColor;
}

.root.hidden {
  animation: dispelBackground $animationDuration ease-out;
  background: $transparentBgColor;
  pointer-events: none;
}

@keyframes blurBackground {
  0% {
    background: $transparentBgColor;
  }
  100% {
    background: $blurBgColor;
  }
}

@keyframes dispelBackground {
  0% {
    background: $blurBgColor;
  }
  100% {
    background: $transparentBgColor;
  }
}
