.root {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 20px 0;
}

.tableRoot {
  --label-width: 600px;
}

.tableContainer {
  max-width: 650px;
  padding-right: 20px;
  padding-left: 40px;
  position: relative;
  max-height: 600px;
  overflow-y: auto;
}

.header {
  padding-left: 15px;
  margin-bottom: 10px;
}

.checkedRowContainer {
  display: flex;
  .checkbox {
    position: absolute;
    left: 0;
    margin: 7px 0;
  }
}
