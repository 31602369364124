.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: min(calc(100vh - 200px), 500px);
  width: 100%;
  padding-block: 30px;
}

.control {
}

.title {
  margin-bottom: 8px;
}

.subtitle {
  margin-bottom: 32px;
}

.scheduleModal {
  box-sizing: border-box;
  height: min(calc(100vh - 60px), 1220px);
  text-align: center;
  padding: 32px 24px;
  overflow: auto;
  .titleContainer {
    max-width: 90%;
  }
}