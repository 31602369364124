@import "@root/theme/colors.scss";

.root {
  position: relative;
  width: 100%;
  padding-left: 30px;
  box-sizing: border-box;
}

.arrow {
  position: absolute;
  margin-inline: -6px;
  margin-block: 20px;
  padding: 10px;
  left: 0;
  transition: transform 0.2s linear;
}

.arrow[data-active="true"] {
  color: $primaryLightColor;
}

.header {
  height: 70px;
  padding: 0 15px;
  border-radius: 10px;
}

.group .header {
  background: #3682911a;
  border: 1px solid transparent;
}

.item .header {
  background: white;
}

.group .body {
  margin-left: -20px;
}

.item .container {
  border-radius: 10px;
  border: 1px solid #eaeaea;
  background: #f9f9f9;
}

.item.opened .header {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.item .body {
  margin: 0;
}
