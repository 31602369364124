@import "@root/theme/utils.scss";

$animationDuration: .2s;

.root {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  min-width: 100px;
  max-width: calc(var(--global-total-width, 100vw) - 50px);
  background: white;
  border-radius: $p08 0 0 $p08;
}

.root.visible {
  animation: appearContainer $animationDuration ease-out;
  transform: translateX(0);
}

.root.hidden {
  animation: disappearContainer $animationDuration ease-in;
  transform: translateX(120%);
}

.closeIcon {
  position: absolute;
  font-size: 1rem;
  width: 48px;
  height: 48px;
  border-radius: $p08 0 0 $p08;
  top: $p24;
  right: 100%;
}

.body {
  overflow: auto;
  max-height: 100%;
  height: 100%;
}

@keyframes appearContainer {
  0% {
    transform: translateX(120%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes disappearContainer {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(120%);
  }
}
