@import "@root/theme/colors.scss";


.color {
  --btn-main-color: gray;
  --btn-second-color: gray;
  --btn-contrast-color: white;
  --btn-focus-border-color: #{$success100Color};
  --btn-focus-main-color: lightGrey;
  --btn-hover-main-color: rgb(112, 112, 112);
  --btn-active-main-color: rgb(87, 86, 86);
  --btn-disabled-main-color: #{$black05Color};
  --btn-disabled-contrast-color: #{$black20Color};
  background: var(--btn-main-color);
  color: var(--btn-contrast-color);
  border-color: var(--btn-main-color);
  
  &-primary {
    --btn-main-color: #{$primary100Color};
    --btn-second-color: #{$primary100Color};
    --btn-contrast-color: #{$white100Color};
    --btn-focus-main-color: #{$primary60Color};
    --btn-hover-main-color: #{$primary120Color};
    --btn-active-main-color: #{$primary140Color};
  }
  
  &-secondary {
    --btn-main-color: #{$secondary100Color};
    --btn-second-color: #{$black60Color};
    --btn-contrast-color: #{$black100Color};
    --btn-focus-main-color: #{$secondary120Color};
    --btn-hover-main-color: #{$secondary120Color};
    --btn-active-main-color: #{$secondary140Color};
  }
  &-light {
    --btn-main-color: #{$white100Color};
    --btn-second-color: #{$white100Color};
    --btn-contrast-color: #{$primary100Color};
    --btn-focus-main-color: #{$secondary10Color};
    --btn-hover-main-color: #{$secondary10Color};
    --btn-active-main-color: #{$secondary20Color};
  }
  &-red {
    --btn-main-color: #{$error100Color};
    --btn-second-color: #{$error100Color};
    --btn-contrast-color: #{$white100Color};
    --btn-focus-main-color: #{$error80Color};
    --btn-hover-main-color: #{$error80Color};
    --btn-active-main-color: #{$error120Color};
  }
  &-yellow {
    --btn-main-color: #{$info100Color};
    --btn-second-color: #{$info100Color};
    --btn-contrast-color: #{$white100Color};
    --btn-focus-main-color: #{$info80Color};
    --btn-hover-main-color: #{$info80Color};
    --btn-active-main-color: #{$info120Color};
  }
  &-green {
    --btn-main-color: #{$success100Color};
    --btn-second-color: #{$success100Color};
    --btn-contrast-color: #{$white100Color};
    --btn-focus-main-color: #{$success80Color};
    --btn-hover-main-color: #{$success80Color};
    --btn-active-main-color: #{$success120Color};
  }
  &-default {
    --btn-main-color: #{$black100Color};
    --btn-second-color: #{$black100Color};
    --btn-contrast-color: #{$white100Color};
    --btn-focus-main-color: #{$black100Color};
    --btn-hover-main-color: #{$black80Color};
    --btn-active-main-color: #{$black90Color};
  }
}
