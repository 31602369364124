@import "@root/theme/utils.scss";

.root {
  outline: none;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
}

.input {
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 21px;
}

.valueContainer {
  display: flex;
  position: relative;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  outline: none;
  border: 1px solid $utilBorder;
  border-radius: 10px;
  background-color: $lightTextColor;
  padding: 7px 45px 7px 14px;
  height: 36px;
  
  .after {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.dropdownContainer {
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  z-index: 1;
}

.dropdownBox {
  padding: 0;
  padding-block: 5px;
  background-color: $lightTextColor;
  border: 1px solid $primaryDarkColor;
  border-radius: $p08;
  // box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  overflow: hidden;
}

.list {
  max-height: 350px;
  min-width: 150px;
  overflow: auto;
  padding: 0;
  margin: 0;
  overflow-anchor: none;
  @include thinScrollbar("&");
}

.arrow {
  right: 4px;
  top: 2px;
  position: absolute;
}


.valueContainer.active {
  border-color: $primaryDarkColor;
}


.root.disabled {
  pointer-events: none;
  .input {
    background: hsla(0, 0%, 92%, 0.5);
  }
}

.pos-x {
  padding-left: 4px;
  top: 0;
  left: 100%;
}

.pos-y {
  padding-top: 4px;
  top: 100%;
  left: 0;
}