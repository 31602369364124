@import "@root/theme/colors.scss"

.primary
  background: -webkit-linear-gradient($primaryLightColor 0%, $primaryDarkColor 100%)
  background-clip: text
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent

.dark
  color: $darkTextColor

.light
  color: $lightTextColor

.gray
  color: $grayTextColor

.darkGray
  color: $darkGray

.red
  color: $redTextColor

.darkBlue
  color: $darkBlueColor

.mediumDarkBlue
  color: $mediumDarkBlueColor

.lightGreen
  color: $lightGreen

.lightGray
  color: $lightGray

.inherit
  color: inherit

$blackColors: '100' , '90' , '80' , '70' , '60' , '40' , '30' , '20' , '10' , '05' , '03'

@each $color in $blackColors
  .black#{$color}Color
    color: var(#{'--black-#{$color}-color'})

$primaryColors: '140', '120', '100', '80', '60', '40', '20', '10', '05'

@each $color in $primaryColors
  .primary#{$color}Color
    color: var(#{'--primary-#{$color}-color'})

$successColors: '120', '100', '80'

@each $color in $successColors
  .success#{$color}Color
    color: var(#{'--success-#{$color}-color'})

$errorColors: '120', '100', '80' , '20'

@each $color in $errorColors
  .error#{$color}Color
    color: var(#{'--error-#{$color}-color'})

$infoColors: '120', '100', '80'

@each $color in $infoColors
  .info#{$color}Color
    color: var(#{'--info-#{$color}-color'})
