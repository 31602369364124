@import "@root/theme/utils.scss";

$totalHeight: var(--global-total-height);
$height: calc(#{$totalHeight} - 95px - var(--sticky-offset));
$sidePadding: 10px;

.header {
  padding: 20px 0 15px 0;
  margin: 0 auto;
  border-bottom: 4px solid $pageBg;
  text-align: center;
  background-color: $lightTextColor;
  width: 100%;
}

.container {
  pointer-events: all;
  max-height: $height;
  min-height: $height;
  height: $height;
}

.box {
  max-height: inherit;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(26, 115, 169, 0.1);
  background-color: $lightTextColor;
  border-radius: 10px 10px;
  overflow: hidden;
}

.totalAmount {
  margin: 0 20px;
}

.footer {
  border-top: 1px solid $primaryLightColor;
  background-color: $lightTextColor;
  padding: 20px 0;
  margin: 0;
  text-align: center;
  box-shadow: 0 4px 20px rgba(26, 115, 169, 0.1);
}

.confirm {
  margin: 20px;
  margin-bottom: 0;
}

.sticky {
  box-shadow: none;
}
