.root {
  display: flex;
  flex-wrap: nowrap;
}

.left {
  display: flex;
  column-gap: 45px;
  flex: 1 1 auto;
  padding: 0 20px;
}

.right {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.selector {
  width: 240px;
}
