.root {
  display: flex;
  padding: 8px;

  & > *:first-child {
    margin-right: 27px;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 30px;

  padding-right: 19px;
  align-items: stretch;
  margin-top: 5px;
}

.wrapper > .column:last-child {
  justify-content: center;
}

.column {
  display: flex;
  flex: 1 1;
  user-select: none;
}

.sortable {
  cursor: pointer;
}

.column:first-child {
  flex: 3 1;
}
.column:last-child {
  flex: 0.5 1;
}

.sortArrow {
  min-width: 15px;
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
  margin-top: 3px;
}
