@import "@root/theme/mixins.scss";
@import "@root/theme/utils.scss";

.root {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  min-width: 2040px;
  padding: 1em 5px;
  position: relative;
  @include secondaryScrollbar();
}

.flexFullHeight {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}

.linkButton {
  font-weight: 500;
  text-align: start;
  white-space: pre-wrap;
  text-decoration: underline;
}

.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  border: none;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  background: rgba(128, 128, 128, 0.111);
}

.miniForm {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  width: 300px;
  margin: 10px 30px;
}

.labelContainer {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  justify-content: space-between;
  width: 100%;
}

.dropdown {
  width: 200px;
}

.campaignRow {
  padding-block: 5px;
}

.row {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: flex-end;
  gap: 10px;
  span {
    padding-block: 10px;
  }
}

.loader {
  margin-left: 70px;
}

.noScroll{
  @include hiddenScrollbar()
}

.lowPerformanceHeader {
  width: 75%;
  box-shadow: 0 4px 15px 0 rgba(32, 32, 32, 0.1);
  background: $white100Color;
  border-radius: 10px;
  margin-bottom: 20px;
}
.collapsibleHeader {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.collapsibleInner {
  padding: 15px 10px 10px;
}
.collapsibleContent {
  margin: 15px 0 0;
}
.performanceSelect {
  min-width: 190px;
}
.performanceButton {
  width: 110px;
}
