@import "@root/theme/colors.scss";

.root {
  display: flex;
  width: 100%;
  gap: 5px;
}

.badge {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  height: 24px;
  width: 24px;
}