@import "@root/theme/colors.scss";

.root {
  box-sizing: border-box;
  font-size: inherit;
  font-weight: 700;
  position: relative;
  width: fit-content;
}

.root:after {
  content: '';
  position: absolute;
  left: 100%;
  animation: loading 1.2s infinite linear;
}

@keyframes loading {
  0% {
    content: '';
  }
  33% {
    content: '.';
  }
  66% {
    content: '..';
  }
  100% {
    content: '...';
  }
}
