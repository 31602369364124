@import "@root/theme/utils.scss";

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: $p24 0;
  margin-top: $p20;
  column-gap: $p30;
}

.header {
  padding-inline: $p24;
  width: auto;
}

.content {
  width: auto;
}

