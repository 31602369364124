@import "@root/theme/colors.scss";

.root {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.wrapper {
  display: flex;
  margin-bottom: 7px;
  cursor: pointer;
  column-gap: 9px;
}

.title {
  height: 20px;
  margin-bottom: 5px;
}

.gray {
  background-color: #f5f5f5;
}

.chunk {
  padding: 20px 30px 30px;
  display: flex;
  flex-direction: column;
  min-height: 310px;
  flex: 1 1 17%;
}

.withLeftBorder {
  border-right: 1px solid $secondaryDarkColor;
}

.withTopBorder {
  border-top: 1px solid $secondaryDarkColor;
}
