.row {
  display: flex;
  border-radius: 8px;
  padding: 0;
}

.header {
  display: flex; 
  text-transform: uppercase;
  color: gray;
  user-select: none;
}

.group {
  flex: 1 1 100%;
  &:not(:last-child) {
    border-right: 1px solid  #21212133;
  }
}

.staticGroup {
  flex: 1 0 auto;
}

.cell {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  height: 68px;
  font-size: 14px;
  &:not(:last-child) {
    border-bottom: 1px solid  #21212133;
  }
}

.before, .after {
  display: flex;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
}

.after {
  flex: 1 0 90px;
  justify-content: center;
  box-sizing: content-box;
}

.clickable {
  cursor: pointer;
}

.sortArrow {
  min-width: 10px;
  font-size: 10px;
  margin-left: 8px;
  display: flex;
  align-items: flex-start;
}

.header .group {
  border-color: transparent;
}

.header :is(.cell, .before, .after) {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 35px;
  height: fit-content;
}
