@import "@root/theme/colors.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-width: 700px;
  padding: 24px;
  padding-top: 10px;
}

.body {
  flex: 1 1 100%;
  width: 100%;
  gap: 5px;
}

.chart {
  width: 100%;
  height: 250px;
}
