@import "@root/theme/colors.scss"

.root
  display: flex
  flex-direction: column
  min-height: 150px
  background: white
  box-shadow: 0 4px 20px rgba(26, 115, 169, 0.1)
  padding: 24px
  border-radius: 10px
  box-sizing: border-box
  flex: 1 1 auto

.title
  display: flex
  flex: 1 1 auto
  font-size: 16px
  i
    margin-left: 5px
    font-size: 1.5em

.toolTip
  max-width: 200px
