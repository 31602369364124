@import "@root/theme/utils.scss";

.root {
  box-sizing: border-box;
  max-height: 100%;
}

.body {
  display: flex;
  flex: 1 1 100%;
  padding-right: $p06;
  max-height: 100%;
  overflow: hidden;
}
