@import "@root/theme/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 320px;
  height: auto;
  min-height: 390px;
  padding: 20px 30px;
  box-sizing: border-box;
  text-align: center;
  img {
    margin-bottom: 12px;
  }
}

.points {
  box-sizing: border-box;
  min-width: 100%;
  padding: 10px 0;
}

.icon {
  width: 112px;
  height: 112px;
}

.infoIcon {
  font-size: 80px;
  border-radius: 50%;
  background: #fffdf6;
  color: #ffc125;
}

.alertIcon {
  font-size: 80px;
  border-radius: 50%;
  background: #ffebeb;
  color: #ff4a4a;
}
