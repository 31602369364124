@import "@root/theme/utils.scss";

.root {
  display: flex;
  flex-direction: column;
}

.body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  gap: $p22
}

.body>* {
  padding: $p16 $p24;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: $p32;
}

.placeholder{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  width: 100%;
}