@import "@root/theme/colors.scss";

.colorMetricsPanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  gap: 10px 10px;
  margin-top: 10px;
}
.colorMetricsSelect {
  position: absolute;
  left: 0;
  top: 40px;
  z-index: 2;
  border: 1px solid $primaryDarkColor;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 4px 15px 0 rgba(32, 32, 32, 0.1);
  background-color: $white100Color;
}
.colorMetricsSelectInput {
  margin-bottom: 10px;
}
