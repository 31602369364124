@import "@root/theme/colors.scss";

.root {
  padding: 20px 20px 5px;
}

.amountCard {
  background-color: $pageBg;
  border-radius: 10px;
  margin-block: 20px;
  max-width: 410px;
  padding: 10px 20px;
  height: 40px;
  flex-shrink: 1;
  box-sizing: border-box;
}

.input {
  width: 250px;
}

.label {
  padding: 0;
}