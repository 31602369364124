@import "@root/theme/colors.scss"
@import "@root/theme/size.scss"

.root
  width: 425px
  padding: 24px

.caption
  margin: 0 16px

.option
  background: $white100Color
  padding: 8px 16px
  border: 1px solid $black20Color

.option:hover *
  cursor: pointer

.option:hover
  background: $white100Color
  border-color: $primary40Color

.option.active
  border-color: $primary100Color

.option.disabled
  pointer-events: none
  cursor: not-allowed
  opacity: 0.5
