@import "@root/theme/utils.scss";

.root {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.scrollable {
  padding-right: $p24;
}
