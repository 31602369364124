.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress {
  & > div > span:last-child {
    display: none;
  }
}

.progressWrapper {
  padding:16px 30px 30px 30px;
  width:100%;
  max-width: 1235px;
  margin: 37px 0 37px 0;
}