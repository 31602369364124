@import '@root/theme/utils.scss';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-block: $p16;
}

.control {
  display: flex;
}

.section {
  display: flex;
  gap: $p16;
  padding-inline: $p16;
  & button {
    font-size: $p20;
  }
  &:not(:last-child) {
    border-right: 1px solid $black20Color;
  }
  .arrow {
    font-size: $p16;
  }
}
