@import '@root/theme/utils.scss';

.subsection {
  width: 550px;
  padding-inline: $p32;
}

.table {
  width: 100%;
  padding: $p06 $p02;
  border: 1px solid $black40Color;
  border-radius: $p08;
}

.input {
  width: 350px;
}