@import "@root/theme/mixins.scss";
@import "@root/theme/colors.scss";
@import "@root/theme/size.scss";

.row {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: flex-end;
  gap: 10px;
  span {
    padding-block: 10px;
  }
}

.header {
  border-bottom: 1px solid $utilBorder;
  padding: $p24 $p24 $p16;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 250px;
  padding: $p16 $p24
}

.footer {
  padding: $p16 $p24
}

.fullWidth {
  width: 100%;
}