.root {
  height: inherit;
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  padding-top: 40px;
  width: 1600px;
  max-width: 1600px;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  z-index: 1;
}

.calculator {
  width: 100%;
}

.keywords {
  display: flex;
  column-gap: 7px;
  flex: 1 1;
  padding-bottom: 92px;
  z-index: 0;
}

.mainTable {
  flex: 1 1;
  margin-bottom: 20px;
}

.selectMonths {
  width: 100%;
}
