@import '@root/theme/colors.scss';

.root {
  width: auto;
  height: 100px;
  border-radius: 4px;
  border: 1px solid $black20Color;
  background: $black05Color;
}

.container {
  position: relative;
  &>.root {
    width: 100%;
    height: 100%;
  } 
}

.topper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  border-radius: 4px;
  background: rgba(33, 33, 33, 0.50);
  color: $white100Color
}