@import "@root/theme/colors.scss";

.root {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  margin: auto;
  height: 100%;
  width: 100%;
}
