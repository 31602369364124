@function p($size) {
  @return (1rem / 16) * $size;
}

$p01: p(1);
$p02: p(2);
$p03: p(3);
$p04: p(4);
$p05: p(5);
$p06: p(6);
$p07: p(7);
$p08: p(8);
$p09: p(9);
$p10: p(10);
$p11: p(11);
$p12: p(12);
$p13: p(13);
$p14: p(14);
$p15: p(15);
$p16: p(16);
$p17: p(17);
$p18: p(18);
$p19: p(19);
$p20: p(20);
$p21: p(21);
$p22: p(22);
$p23: p(23);
$p24: p(24);
$p25: p(25);
$p26: p(26);
$p27: p(27);
$p28: p(28);
$p29: p(29);
$p30: p(30);
$p31: p(31);
$p32: p(32);
$p33: p(33);
$p34: p(34);
$p35: p(35);
$p36: p(36);
$p37: p(37);
$p37: p(37);
$p38: p(38);
$p39: p(39);
$p40: p(40);
$p45: p(45);

$p60: p(60);
