@import "@root/theme/colors.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-height: 600px;
  padding: 30px 30px 20px 30px;
}

.body {
  display: flex;
  gap: 5px;
  margin-bottom: 15px;
  max-height: 600px;
}

.mapContainer {
  width: 800px;
  height: 600px;
}
