@import '../../../theme/colors.scss';

.root {
  display: flex;
  background: $lightTextColor;
  border: 1px solid $lightTextColor;
  border-radius: 8px;
  min-height: 80px;
  overflow: hidden;
  box-sizing: border-box;
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  color: $lightTextColor;
  min-width: 78px;
  width: 78px;
  border-radius: 7px 0 0 7px;
  height: auto;
  background: linear-gradient(180deg, $primaryLightColor 0%, $primaryDarkColor 100%);
}

.content{
  font-family: 'Poppins';
  display: flex;
  align-items: center;
  padding: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $darkTextColor;
}