@import '@root/theme/utils.scss';

.root {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
  color: black;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  &::before {
    content: '';
    width: 6px;
    height: 6px;
    margin-right: 8px;
    background: black;
    border-radius: 50%;
  }
}

.secondary {
  color: $black70Color;
  &:before {
    background: $black70Color;
  }
}

.success, .green {
  color: $success100Color;
  &:before {
    background: $success100Color;
  }
}

.info, .yellow {
  color: $info120Color;
  &:before {
    background: $info120Color;
  }
} 

.error, .red {
  color: $error100Color;
  &:before {
    background: $error100Color;
  }
}

.root.variant-simple {
  font-weight: inherit;
  font-size: inherit;
  text-transform: none;
}