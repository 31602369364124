@import '@root/theme/utils.scss';

.root {
  width: 100%;
}

.section {
  padding: $p24;
  padding-right: 50%;
}

.field {
  width: 350px;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-block: $p03;
  gap: $p12
}