@import "@root/theme/utils.scss";

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: $p24;
  margin-top: $p20;
  column-gap: $p30;
}

.card {
  flex: 1 1 50%;
  max-width: 50%;
}