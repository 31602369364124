@import '@root/theme/utils.scss';

.root {
  width: 100%;
  font-family: Roboto, Helvetica, sans-serif;
  padding-bottom: $p24;
}

.body {
  box-sizing: border-box;
  background-color: $black03Color;
  padding-block: $p30;
  padding-inline: $p20;
  border-radius: $p08;
  min-height: 340px;
}
