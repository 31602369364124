@import '@root/theme/colors.scss'

.root
  display: flex
  align-items: center
  justify-content: center
  border-radius: 4px
  height: 24px
  width: 24px
  font-size: 18px
  color: $darkGray

.root:hover
  transition: background-color .1s
  color: $darkTextColor
  background-color: #eeeeee
