.root {
  padding-inline: 24px;
  padding-block: 18px 24px;
}

.header {
  display: flex;
  column-gap: 20px;
  & > * {
    width: 220px;
  }
}
