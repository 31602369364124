@import "@root/theme/utils.scss";

.pair {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 10px 0 30px;
  align-items: flex-start;
  padding: 5px 0;
}

.closeButton {
  padding: 10px;
  font-size: 10px;
  position: absolute;
  left: -30px;
  top: 0;
  cursor: pointer;
}

.pair.closable:hover > * {
  color: $primaryLightColor;
}

.column {
  flex: 2 1;
  text-align: start;
  &:last-child {
    flex: 1 1;
    text-align: end;
  }
}
