.root {
  display: block;
  box-sizing: border-box;
  text-align: start;
  position: relative;
}

.label {
  margin-bottom: 4px;
}

.floating {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
}