@import "@root/theme/colors.scss";

.title {
  margin: 81px 0 11px 0;
}

.row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 100px;
  padding: 10px;
}

.icon {
  width: 18px;
  height: 18px;
  margin-right: 7px;
}

.cell {
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 22px;
}

.button {
  width: 150px;
  max-height: 40px;
}

.status {
  display: flex;

  & > div:first-child {
    margin-right: 12px;
  }
}

.rowContainer {
  display: grid;
  grid-template-columns: 200px 1fr 1fr 150px 100px;
  grid-column-gap: 75px;
  align-items: center;

  & > * {
    margin-bottom: 3px;
  }
}

.box {
  margin-bottom: 22px;
  padding: 10px 20px;
}

.iconWrapper {
  width: 18px;
  height: 18px;
  background: linear-gradient(180deg, #5cbd9f 0%, #17899e 100%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 9px;

  & i {
    color: white;
    font-size: 11px;
  }
}
