@import '@root/theme/utils';

.listingGroup {
    background-color: $white100Color;
    padding: 24px;
    border-radius: 10px;
    box-shadow: $primaryShadowL1;
    margin-bottom: 24px;
}
.listingSubtitle {
    margin-bottom: 8px;
}
.buttonIcon {
    padding-left: 0;
}
.sidebar {
    position: relative;
    min-height: 100%;
    padding: 34px;
    box-sizing: border-box;
}
.sidebarFooter {
    background-color: $white100Color;
    position: fixed;
    bottom: 0;
    height: 84px;
    padding: 24px;
    right: 0;
    left: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: $secondaryShadowL3;
    z-index: 2;
}
.sidebarButton {
    margin-right: 16px;
}

.optionsModal {
    min-width: 700px;
    padding: 0 25px 25px;
}
.optionsList {
    list-style: none;
    margin: 0;
    padding: 0 0 30px 0;
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
    box-sizing: border-box;
}
.optionsItem {
    margin: 0;
    padding: 10px 20px 10px 0;
    border-bottom: 1px solid $black10Color;
}
.optionsItemLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.optionsItemCheckboxWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.optionsItemCheckbox {
    margin-right: 10px;
}
.emptyList {
    margin: 0 0 20px 0
}
