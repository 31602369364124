@import "@root/theme/colors.scss"
@import "@root/theme/size.scss"

.root
  display: flex
  flex-direction: column
  align-items: stretch
  height: fit-content
  width: min(1000px, 90vw)
  box-sizing: border-box

.header
  display: flex
  flex-direction: row
  padding: $p24
  padding-bottom: $p16
  justify-content: space-between

.body
  display: flex
  align-items: stretch
  padding: $p24
  padding-top: 0
  overflow: auto
  flex: 1

.title
  display: flex
  padding: 10px
  border-radius: $p08
  border: 1px solid $black40Color
  gap: $p08
