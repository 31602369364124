.title {
  margin: 28px 0 17px 0;
}

.control {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  user-select: none;
}

.section {
  margin-block: 6px 18px;
}