.root {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 0 10px;
}

.button {
  max-width: 65px;
  max-height: 32px;
  padding: 5px !important;
  white-space: pre-wrap;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 108%;
}
