@import '@root/theme/utils.scss';

.root {
  width: 100%;
}

.content {
  min-height: max(400px, 50vh);
}

.header {
  display: flex;
  flex-direction: column;
  gap: $p16;
}

.section {
  padding: 0;
  &>*:not(:last-child) {
    border-bottom: 1px solid $black20Color
  }
}

.createAssetGroup {
  margin-top: 25px;
}
