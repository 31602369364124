@import '@root/theme/colors.scss';

.root {
  display: flex;
  gap: 5px;
  i {
    color: #868686
  }
}

.message {
  max-width: 200px;
}

