@import "@root/theme/colors.scss";
@import "@root/theme/size.scss";

.root {
  display: flex;
  flex-direction: column;
  //overflow: auto;
}

.body {
  max-width: 100%;
  //height: auto;
  flex: 1;
}

.headerContainer,
.footerContainer {
  padding-right: 13px;
  align-items: center;
}
.row {
  cursor: pointer;
  &:hover {
    background: $primary10Color;
  }
}
