.root {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
}

.header {
  margin: 0 32px;
  border-bottom: 1px solid #21212166;
}

.body {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding-right: 10px;
  margin: 0 16px 0 32px;
  cursor: default;
  .row:nth-child(odd) {
    background-color: #F6F6F6;
  }
}

.row {
  display: flex;
  padding: 9px 16px;
  gap: 20px;
  &:not(:first-child) {
    border-top: 1px solid #21212166;
  }
}

.col {
  flex: 1 1 100%;
  flex-wrap: wrap;
  min-height: 22px;
  &:first-child {
    flex: 1 0 200px;
  }
}

.toolTip {
  padding: 0;
}

.label {
  margin: 9px 0;
}