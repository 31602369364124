.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rightSection {
  display: flex;
  align-items: center;
  gap: 24px;
  flex: 1 1 100%
}

.leftSection {
  display: flex;
}

.centerSection {
  display: flex;
  gap: 24px
}

.title {
  display: flex;
  align-items: center;
  gap: 16px;
  min-width: 300px;
}

.input {
  width: 250px;
}
