@import "@root/theme/colors.scss";

.root {
  max-width: 350px;
  & > .stars {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    align-items: center;
    margin-bottom: 6px;
    height: 27px;
    gap: 20px;
    & > div {
      align-items: center;
      display: flex;
      & > span {
        color: gray;
      }
    }
  }
}

.row {
  display: flex;
  color: black;
  margin-bottom: 3px;
  & > * {
    line-height: 20px !important;
  }
}

.capitalize {
  text-transform: capitalize;
}
