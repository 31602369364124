@import "@root/theme/colors.scss";

.root {
  position: relative;
  height: var(--global-total-height, 100vh);
  background: $pageBg;
  min-height: inherit;
  min-width: inherit;
  height: fit-content;
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: column;
  flex-grow: 1;
  align-content: stretch;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px; 
}

.body {
  display: flex;
  width: 100%;
  flex: 1 1;
  padding-block: 30px;
}

.bodyContainer {
  display: flex;
  padding-inline: 80px;
  max-width: 1280px;
  min-width: 1280px;
  margin: 0 auto;
  width: 100%;
}

@media (max-width: 1100px) {
  .bodyContainer {
    padding-inline: 60px;
    max-width: 100%;
  }
}
