$shadowWidth: 8px;

.root {
  position: relative;
  overflow: hidden;
}

.leftShadow, .rightShadow {
  position: absolute;
  width: $shadowWidth;
  top: 0;
  bottom: 0;
}

.topShadow, .bottomShadow {
  position: absolute;
  height: $shadowWidth;
  left: 0;
  right: 0;
}

.rightShadow {
  content: '';
  left: 0;
  background: linear-gradient(
    to left,
    #f6f6f600 0%,
    #e1e1e1 100%
  )
}

.leftShadow {
  content: '';
  right: 0;
  background: linear-gradient(
    to right,
    #f6f6f600 0%,
    #e1e1e1 100%
  )
}

.topShadow {
  content: '';
  bottom: 0;
  background: linear-gradient(
    to bottom,
    #f6f6f600 0%,
    #c3c3c3ce 100%
  )
}

.bottomShadow {
  content: '';
  top: 0;
  background: linear-gradient(
    to top,
    #f6f6f600 0%,
    #c3c3c3ce 100%
  )
}