@import "@root/theme/colors.scss";

.textAreaField {
  height: auto;
  min-height: 100%;
}

.selector {
  & > div {
    border: 1px solid $utilBorder;
    border-radius: 10px;
    box-shadow: none !important;
    &:focus-within {
      border-color: $darkTextColor;
    }
  }
}
