@import "@root/theme/colors.scss";

.root {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  user-select: none;
  background: $lightTextColor;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    color: $primaryLightColor;
  }
}

.root[data-drag=true] {
  box-shadow: 0px 4px 15px rgba(32, 32, 32, 0.1);
}

.label {
  flex: 1 1 auto;
}
