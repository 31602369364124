@import "@root/theme/colors.scss";

.root {
  position: relative;
  height: var(--global-total-height, 100vh);
  background: $pageBg;
  min-height: inherit;
  min-width: inherit;
  height: fit-content;
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: column;
  flex-grow: 1;
  align-content: stretch;
  z-index: 1;
}

.header {
  z-index: 3;
}

.mainHeader {
  z-index: 1000;
}

.body {
  display: flex;
  width: 100%;
  flex: 1 1;
  padding-block: 50px;
}

.bodyContainer {
  display: flex;
  padding-inline: 120px;
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
}

.backButton {
  position: absolute;
  left: 60px;
}

@media (max-width: 1100px) {
  .body {
    padding-block: 30px;
  }
  .bodyContainer {
    padding-inline: 80px;
    max-width: 100%;
  }
  .squareButton {
    left: 20px;
  }
}
