.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.body {
  display: flex;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
}

.stepper {
  flex: 1 1 100%;
  justify-content: center;
}
