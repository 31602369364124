@import "@root/theme/colors.scss";

.react-calendar {
  &, * {
    font-family: inherit;
  }
}

.react-calendar__navigation {
  display: flex;
  text-align: center;
  justify-content: space-between;
  & > * {
    padding: 4px 8px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
  & > *:disabled {
    cursor: not-allowed;
  }
  &__label {
    min-width: 90px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
  }
  &__prev-button,
  &__next-button {
    font-size: 36px;
    line-height: 25px;
    color: $black60Color;
    &:disabled {
      opacity: .3;
    }
  }
  &__next2-button,
  &__prev2-button {
    display: none;
  }
}

.react-calendar__month-view__weekdays {
  padding: 12px 0;
  & * {
    text-decoration: none;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: $black60Color;
  }
}

.react-calendar__month-view__days {
  padding-top: 3px;
  border-top: 1px solid $black20Color;
}

.react-calendar__tile {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: $black100Color;
}

// days
.react-calendar__month-view__days__day { 
  &.react-calendar__tile {
    padding-inline: 2.5px;
    margin: 0;
    margin-block: 2.5px;
    position: relative;

    & > abbr {
      width: 32px;
      height: 32px;
      border-radius: 8px;
      padding: 5px;
      box-sizing: border-box;
      border: 1px solid transparent;
      z-index: 0;
    }
  
    &:disabled {
      cursor: not-allowed;
    }
  
    &--range, &--hover {
      &:before {
        content: '';
        left: 0;
        right: 0;
        height: 32px;
        position: absolute;
      }
    }
    &--range:before, &--hover:before {
      background: $primary20Color;
    }
    &--rangeStart:before {
      border-radius: 8px 0 0 8px;
      right: 0;
      left: calc(50% - 16px);
    }
    &--rangeEnd:before {
      border-radius: 0 8px 8px 0;
      left: 0;
      right: calc(50% - 16px);
    }
    &--rangeBothEnds:before {
      border-radius: 8px;
      left: calc(50% - 16px);
      right: calc(50% - 16px);
    }
    &--rangeStart, &--rangeEnd, &--rangeBothEnds {
      color: $white100Color;
      & > abbr {
        background: $primary100Color;
      }
    }

    // hover
    &--hoverStart:before {
      border-radius: 8px 0 0 8px;
      right: 0;
      left: calc(50% - 16px);
    }
    &--hoverEnd:before {
      border-radius: 0 8px 8px 0;
      left: 0;
      right: calc(50% - 16px);
    }
    &--hoverBothEnds:before {
      border-radius: 8px;
      left: calc(50% - 16px);
      right: calc(50% - 16px);
    }

    &:nth-child(7n) {
      border-radius: 0 8px 8px 0;
    }
    &:nth-child(7n + 1) {
      border-radius: 8px 0 0 8px;
    }
  }

  &--weekend {
    &.react-calendar__tile {
      font-weight: 600;
    }
  }

  &--neighboringMonth {
    &.react-calendar__tile {
      color: $black60Color;
      &--rangeStart, &--rangeEnd, &--rangeBothEnds {
        color: $white100Color;
      }
    }
  }

  &:hover > abbr, &:focus > abbr {
    border-color: $primary100Color;  
  }
}

// months, years, decades
.react-calendar__year-view__months, 
.react-calendar__decade-view__years, 
.react-calendar__century-view__decades {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid $black20Color;
  .react-calendar__tile {
    width: 100%;
    height: 40px;
    padding: 10px;
    border: 1px solid transparent;
    margin-block: 3px;
    cursor: pointer;
    &--hasActive {
      border-radius: 8px;
    }
    &--rangeStart {
      border-radius: 8px 0 0 8px;
    }
    &--rangeEnd {
      border-radius: 0 8px 8px 0;
    }
    &--rangeBothEnds {
      border-radius: 8px;
    }
    &--active, &--hasActive {
      background: $primary20Color;
    }
    &:hover, &:focus {
      background: $primary40Color;
      border-color: $primary100Color;
      border-radius: 8px;
    }
  }
}

.react-calendar__tile:disabled {
  opacity: .3;
  & > abbr, &:hover, &:focus {
    background: transparent;
    border-color: transparent;
    cursor: not-allowed;
  }
  &::before {
    content: none;
  }
}