@import "@root/theme/utils.scss";

.root {
  box-sizing: border-box;
  padding-block: $p40 $p60;
  width: 100%;
  min-width: 1400px;
}

.root.disabled {
  pointer-events: none;
  opacity: .7;
}

.searchInput {
  min-width: 400px;
}

.body {
  display: grid;
  grid-template-columns: 500px 1fr;
}

.section:first-child {
  height: calc(100vh - 325px);
}

.section:last-child {
  min-height: calc(100vh - 325px);
  border-left: $p01 solid $black20Color;
}
