@import "@root/theme/utils.scss";

.root {
  overflow: auto;
  height: 100%;
}

.header {
  padding: $p32;
  padding-bottom: $p24;
}

.body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1 1 100%;
  width: 350px;
  padding-inline: $p14;
}

.totalAmount {
  margin: 0;
  margin-bottom: 20px;
}

.footer {
  border-top: 1px solid $primaryLightColor;
  background-color: $lightTextColor;
  padding: $p24 $p32;
  margin: 0;
  text-align: center;
  box-shadow: 0 4px 20px rgba(26, 115, 169, 0.1);
}