.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 340px;
  height: min(100vh, 440px);
  padding: 20px 30px;
  box-sizing: border-box;
  text-align: center;
  img {
    margin-bottom: 12px;
  }
}


.subtitle {
  margin-block: 10px;
  span {
    color: rgb(86, 86, 86);
    font-weight: 700;
  }
}

.control {
  min-width: 150px;
}