@import '@root/theme/utils.scss';

.root {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding-block: 10px;
  border-radius: $p08;
  border: 1px solid $black20Color;
  padding: $p16;
  gap: $p16;
}

.body {
  display: grid;
  grid-template-columns: 70% 1fr;
  gap: $p16;
}

.content {
  border-right: 1px solid $black20Color;
}

.section {
  display: flex;
  flex-direction: column;
  gap: $p08;
}