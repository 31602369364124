@import "@root/theme/colors.scss"
@import "@root/theme/size.scss"

.root
  display: block
  border: 1px solid $utilBorder
  background: transparent
  padding: $p24 $p32
  text-align: start
  cursor: pointer

.root:hover, .active
  border-color: $primary100Color

.active
  background: $primary05Color

.rounded
  border-radius: $p08
