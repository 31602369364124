@import "@root/theme/utils.scss";

.root {
  display: flex;
  flex-direction: column;
}

.body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 300px 275px 275px;
  gap: $p22
}

.body>* {
  padding: $p16 $p24;
}

.chart {
  grid-column: 1 / 3;
}

.relatedTopics {
  grid-column: 1 / 2;
}

.topQuestions {
  grid-column: 1 / 2;
}

.topKeywords {
  grid-column: 2 / 3;
  grid-row: 2 / 4;
}

// temp 
.body.hideDisabled {
  .topKeywords {
    grid-column: 1 / 2;
    grid-row: 2 / 4;
  }
}
