@import "@root/theme/colors.scss";

.root {
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-block: 24px;
  padding-inline: 20px;
  min-height: 88px;
  box-sizing: border-box;
}

.body {
  margin-top: 20px;
}

.tableContainer {
  padding: 20px;
  padding-top: 20px;
}

.form {
  display: flex;
  justify-content: space-between;
  width: 79%;
  padding: 0 24px;

  & > div {
    width: 134px;
  }
}

.divider {
  background: $secondaryDarkShadow;
  height: 1px;
  width: auto;
  margin: 0 30px;
}

.chartCard {
  margin-top: 7px;
  &::after {
    content: "";
    position: absolute;
    left: -25px;
    top: -20px;
    width: calc(100% + 50px);
    height: 30px;
    z-index: -1;
    background: linear-gradient(
      transparent,
      $pageBg 30%,
      $pageBg 70%,
      transparent
    );
  }
}

.chartContainer {
  padding-inline: 24px;
  height: 262px;
}
