@import "@root/theme/utils.scss";

.root {
  display: flex;
  flex-direction: column;
  padding: $p16;
  border: $p01 solid $primary10Color;
  border-radius: $p08;
  gap: $p12;
}

.title {
  width: 180px;
}

.content>* {
  flex: 1 1 100%;
}

.metric {
  justify-content: flex-start;
}

.root.inline {
  flex-direction: row;
  align-items: center;
}
