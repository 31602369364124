@import "@root/theme/utils.scss";

.root {
  flex: 0 1 auto;
}

.details {
  min-width: 250px;
}

.control {
  flex: none
}