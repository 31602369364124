@import "@root/theme/mixins.scss";

.scrollable {
  -ms-overflow-style: none;
  overflow: auto;
}

.none {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    width: 10px;
    background-color: transparent;
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    background: gray;
    border: 4px solid transparent;
    background-clip: content-box;
  }
}

.secondary {
  @include thinScrollbar('&');
}
