@import "@root/theme/colors.scss";

.root {
  display: block;
  padding: 4px 0;
  margin: 0;
  user-select: none;
  cursor: pointer;
}

.root:not(:last-of-type)::after {
  content: "";
  position: relative;
  display: block;
  height: 1px;
  width: calc(100% - 24px);
  margin-inline: 12px;
  bottom: -4px;
  background-color: $utilBorder;
}

.title {
  padding-inline: 12px;
  margin-block: 4px;
}
