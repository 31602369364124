@import "@root/theme/colors.scss"
@import "@root/theme/size.scss"

.root
  width: 550px

.card
  display: block
  border: 1px solid $utilBorder
  border-radius: $p10
  padding: $p24

  &>*:nth-child(1)
    margin-bottom: $p10

  &>*:nth-child(2)
    margin-bottom: $p16

  &.green
    border-color: #17899E

  &.blue
    border-color: #2D8DD1

.blue
  --btn-main-color: #2D8DD1
  --btn-second-color: #2D8DD1
  --btn-contrast-color: white
  --btn-focus-main-color: #3991d0
  --btn-hover-main-color: #2281c5
  --btn-active-main-color: #196ca8
