.title {
  margin: 61px 0 28px 0;
}

.results {
  display: flex;
  justify-content: space-between;
  margin-top: 48px;

  & > div {
    min-width: 220px;
    max-width: 220px;
  }
}

.container {
  & > div:not(:last-child) {
    border-bottom: 1px solid #eaeaea;
  }
}

.row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 27px;
  padding: 7px 0;

  & > div:last-child {
    justify-self: end;
  }
}

.empty {
  text-align: center;
  margin: 10px auto;
}
