@import "@root/theme/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  user-select: none;
  color: $darkTextColor;
  background: $utilBg;
  border: 2px dashed $utilBorder;
  border-radius: 10px;
  width: 100%;
  height: 106px;
}

.container.active {
  border-color: $primaryLightColor;
}

.container:focus,
.container:hover {
  outline: none;
  border-color: $primaryDarkColor;
}

.disabled,
.disabled:hover {
  outline: none;
  background-color: $secondaryDarkColor;
  border-color: $utilBorder;
}

.rejectedFilesList {
  margin: 20px 0 0;
  ul {
    list-style: none;
  }
}

