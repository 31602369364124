@import "@root/theme/colors.scss";

.body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: $lightTextColor;
  border-radius: 8px;
  padding: 32px;
  width: 455px;
  max-width: 90vw;
  box-sizing: border-box;
  position: relative;
  justify-self: flex-end;
}

.logo {
  letter-spacing: 2px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.004em;
  margin: 0;
}

.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.004em;
  margin: 0;
}

.list {
  padding: 0;
  margin-block: 8px;
  li {
    display: flex;
    font-size: 16px;
    line-height: 24px;
    margin-block: 12px;
  }
  li > i {
    margin-right: 15px;
    font-size: 20px;
  }
  li::marker {
    display: none;
  }
}

.control {
  display: grid;
  grid-template-columns: 160px 1fr;
}

.alertContainer {
  width: 100%;
  position: absolute;
  left: 0;
  top: calc(100% + 24px);
}

.alert {
  visibility: hidden;
}

.alert.visible {
  animation: reveal-alert 0.2s ease-out;
  visibility: visible;
}

.alert.hidden {
  animation: fade-alert 0.2s ease-out;
  visibility: hidden;
}

@keyframes reveal-alert {
  0% {
    visibility: hidden;
    transform: translateY(20px);
    opacity: 0;
  }
  1% {
    visibility: visible;
  }
  100% {
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-alert {
  0% {
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
  }
  99% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
    transform: translateY(20px);
    opacity: 0;
  }
}
