@import "@root/theme/utils.scss";

.root {
  display: flex;
  flex-direction: column;
  width: min(650px, 90vw);
  height: min(450px, 90vh);
  padding: $p16 $p24 $p24;
  overflow: auto;
  box-sizing: border-box;
}

.dropdown {
  width: 250px;
}