@import "@root/theme/colors.scss";

.root {
  box-sizing: border-box;
  height: 80px;
  width: 80px;
  margin: 0;
  animation: loading .7s infinite linear;
}

.circle {
  width: 100%;
  height: 100%;
  background-image: conic-gradient(from 180deg, transparent 0%, transparent 25%, currentColor 100% );
}

.tip {
  fill: currentColor;
  stroke: none;
}

.root.size-big {
  height: 80px;
  width: 80px;
}

.root.size-inherit {
  height: 1.2em;
  width: 1.2em;
}

.root.color-primary {
  .tip {
    fill: $primaryDarkColor;
  }

  .circle {
    background-image: conic-gradient(from 180deg, transparent 0%, transparent 25%, $primaryDarkColor 100% );
  }
}

@keyframes loading {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}
