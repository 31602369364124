@import "@root/theme/utils.scss";

.search {
  margin-inline: $p08;
  margin-bottom: $p08;
}

.groupHeader {
  padding-inline: $p12;
  width: 100%;
  background: $white100Color;
}