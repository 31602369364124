@import '@root/theme/utils.scss'

.root
  display: grid
  grid-template-rows: 0fr
  transition: grid-template-rows 250ms
  width: 100%

.container
  overflow: hidden

.opened
  grid-template-rows: 1fr
