@import "@root/theme/colors.scss";

.root {
  position: relative;
  margin: 0 0.5em;
  display: flex;
  justify-content: center;
  font-size: 1.3em;
}

.root.gotSome::after {
  top: 9px;
  right: 0px;
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  background-color: $redTextColor;
  border-radius: 50%;
  border: 1px solid $lightTextColor;
}
