@import "@root/theme/colors.scss"
@import "@root/theme/size.scss"

.root
  display: flex
  flex-direction: column
  gap: $p08
  background: $white100Color
  padding: 12px
  border: 1px solid transparent
  cursor: pointer
  overflow: hidden

.root:hover
  background: $primary10Color

.root:active,
.root.active
  border-color: $primary40Color
  background: $primary10Color

.header
  display: flex
  justify-content: space-between
  gap: $p08

.body
  display: flex
  flex-direction: column
  justify-content: flex-start
  gap: $p04
