.root {
  width: 100%;
  padding: 40px 0 90px 0;
  height: max-content;
}

.wrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: 40px;

  & > div {
    flex: 1 1;
  }
}
