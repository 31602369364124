@import "@root/theme/colors.scss";

@font-face {
  font-family: "icomoon";
  src: url("../../../assets/fonts/icomoon/icomoon.eot?rxmul2");
  src: url("../../../assets/fonts/icomoon/icomoon.eot?rxmul2#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/icomoon/icomoon.ttf?rxmul2") format("truetype"),
    url("../../../assets/fonts/icomoon/icomoon.woff?rxmul2") format("woff"),
    url("../../../assets/fonts/icomoon/icomoon.svg?rxmul2#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon,
:global(.icon) {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  font-size: 1em;
  transition: transform 0.2s;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gradient {
  color: $primary100Color;
}

.circle {
  margin: -0.2em 0.2;
  height: 1em;
  width: 1em;
  padding: calc(2em / 14);
  border-radius: 50%;
  color: $primaryDarkColor;
  background: $lightTextColor;
}

.clickable {
  cursor: pointer;
}

.icon-user-add:before {
  content: "\e996";
}
.icon-user-add-fill:before {
  content: "\e998";
}
.icon-briefcase-3:before {
  content: "\e997";
}
.icon-shapes:before {
  content: "\e992";
}
.icon-heart-fill:before {
  content: "\e993";
}
.icon-heart:before {
  content: "\e994";
}
.icon-shapes-fill:before {
  content: "\e995";
}
.icon-file-list-part-fill:before {
  content: "\e988";
}
.icon-file-list-part:before {
  content: "\e989";
}
.icon-file-list-fill:before {
  content: "\e98a";
}
.icon-file-list:before {
  content: "\e98b";
}
.icon-history:before {
  content: "\e98c";
}
.icon-question-circle-fill:before {
  content: "\e98e";
}
.icon-question-circle:before {
  content: "\e98f";
}
.icon-rest-time-fill:before {
  content: "\e990";
}
.icon-rest-time:before {
  content: "\e991";
}
.icon-fullscreen-enter:before {
  content: "\e980";
}
.icon-picture-in-picture-enter:before {
  content: "\e981";
}
.icon-picture-in-picture-exit:before {
  content: "\e982";
}
.icon-picture-in-picture:before {
  content: "\e983";
}
.icon-arrow-up-double:before {
  content: "\e984";
}
.icon-contract-up-down:before {
  content: "\e985";
}
.icon-expand-up-down:before {
  content: "\e986";
}
.icon-fullscreen-exit:before {
  content: "\e987";
}
.icon-folder-settings-fill:before {
  content: "\e979";
}
.icon-folder-settings:before {
  content: "\e97a";
}
.icon-gear-8-fill:before {
  content: "\e97b";
}
.icon-gear-8:before {
  content: "\e97c";
}
.icon-file-copy-fill:before {
  content: "\e97d";
}
.icon-file-copy:before {
  content: "\e97e";
}
.icon-repeat:before {
  content: "\e977";
}
.icon-focus-2:before {
  content: "\e978";
}
.icon-add-circle-fill:before {
  content: "\e96b";
}
.icon-add-circle:before {
  content: "\e96c";
}
.icon-checkbox-blank-circle-fill:before {
  content: "\e96d";
}
.icon-checkbox-blank-circle:before {
  content: "\e96e";
}
.icon-checkbox-circle-fill1:before {
  content: "\e96f";
}
.icon-checkbox-circle1:before {
  content: "\e970";
}
.icon-close-circle-fill:before {
  content: "\e971";
}
.icon-close-circle:before {
  content: "\e972";
}
.icon-indeterminate-circle-fill:before {
  content: "\e973";
}
.icon-indeterminate-circle:before {
  content: "\e974";
}
.icon-radio-button-fill:before {
  content: "\e975";
}
.icon-radio-button:before {
  content: "\e976";
}
.icon-add:before {
  content: "\e969";
}
.icon-subtract:before {
  content: "\e96a";
}
.icon-checkbox-blank-fill:before {
  content: "\e95b";
}
.icon-checkbox-blank:before {
  content: "\e95c";
}
.icon-checkbox-fill:before {
  content: "\e95d";
}
.icon-checkbox-indeterminate-fill:before {
  content: "\e95e";
}
.icon-checkbox-indeterminate:before {
  content: "\e95f";
}
.icon-checkbox:before {
  content: "\e960";
}
.icon-file-chart-fill:before {
  content: "\e961";
}
.icon-file-chart:before {
  content: "\e962";
}
.icon-menu:before {
  content: "\e963";
}
.icon-more-vertical:before {
  content: "\e964";
}
.icon-more-horizontal:before {
  content: "\e965";
}
.icon-sort-asc:before {
  content: "\e966";
}
.icon-sort-desc:before {
  content: "\e967";
}
.icon-add-box-fill:before {
  content: "\e968";
}
.icon-mail-fill:before {
  content: "\e942";
}
.icon-mail:before {
  content: "\e943";
}
.icon-mail-open-fill:before {
  content: "\e944";
}
.icon-mail-open:before {
  content: "\e945";
}
.icon-money-dollar-box-fill:before {
  content: "\e946";
}
.icon-money-dollar-box:before {
  content: "\e949";
}
.icon-money-dollar-circle-fill:before {
  content: "\e94b";
}
.icon-money-dollar-circle:before {
  content: "\e94d";
}
.icon-gear-fill:before {
  content: "\e94e";
}
.icon-gear:before {
  content: "\e94f";
}
.icon-user-star-fill:before {
  content: "\e950";
}
.icon-user-star:before {
  content: "\e951";
}
.icon-file-info-fill:before {
  content: "\e952";
}
.icon-file-info:before {
  content: "\e953";
}
.icon-lock-password-fill:before {
  content: "\e954";
}
.icon-lock-password:before {
  content: "\e955";
}
.icon-refresh-fill:before {
  content: "\e936";
}
.icon-refresh:before {
  content: "\e937";
}
.icon-share-circle:before {
  content: "\e938";
}
.icon-vip-crown-fill:before {
  content: "\e939";
}
.icon-vip-crown:before {
  content: "\e93a";
}
.icon-vip-diamond-fill:before {
  content: "\e93b";
}
.icon-vip-diamond:before {
  content: "\e93c";
}
.icon-bank-card-fill:before {
  content: "\e93d";
}
.icon-bank-card:before {
  content: "\e93e";
}
.icon-check-double:before {
  content: "\e93f";
}
.icon-alert:before {
  content: "\e940";
}
.icon-alert-fill:before {
  content: "\e941";
}
.icon-checkbox-circle-fill:before {
  content: "\e92b";
}
.icon-checkbox-circle:before {
  content: "\e92c";
}
.icon-filter:before {
  content: "\e92d";
}
.icon-loader:before {
  content: "\e92e";
}
.icon-magic-fill:before {
  content: "\e92f";
}
.icon-magic:before {
  content: "\e930";
}
.icon-shield-check-fill:before {
  content: "\e931";
}
.icon-shield-check:before {
  content: "\e932";
}
.icon-stack-fill:before {
  content: "\e933";
}
.icon-stack:before {
  content: "\e934";
}
.icon-archive-drawer-fill:before {
  content: "\e929";
}
.icon-archive-drawer:before {
  content: "\e92a";
}
.icon-home-gear-fill:before {
  content: "\e919";
}
.icon-home-gear:before {
  content: "\e91a";
}
.icon-hotel-fill:before {
  content: "\e91b";
}
.icon-hotel:before {
  content: "\e91c";
}
.icon-lightbulb-fill:before {
  content: "\e91d";
}
.icon-lightbulb:before {
  content: "\e91e";
}
.icon-list-check:before {
  content: "\e91f";
}
.icon-user-fill:before {
  content: "\e920";
}
.icon-user:before {
  content: "\e921";
}
.icon-auction-fill:before {
  content: "\e922";
}
.icon-auction:before {
  content: "\e923";
}
.icon-dashboard-fill:before {
  content: "\e924";
}
.icon-dashboard:before {
  content: "\e925";
}
.icon-bill-fill:before {
  content: "\e926";
}
.icon-bill-line:before {
  content: "\e927";
}
.icon-focus-line:before {
  content: "\e928";
}
.icon-triangle:before {
  content: "\e97f";
}
.icon-reset:before {
  content: "\e935";
}
.icon-information-outline:before {
  content: "\e902";
}
.icon-information-solid:before {
  content: "\e918";
}
.icon-display:before {
  content: "\e956";
}
.icon-laptop:before {
  content: "\e957";
}
.icon-cell-phone:before {
  content: "\e958";
}
.icon-mobile:before {
  content: "\e959";
}
.icon-tablet:before {
  content: "\e95a";
}
.icon-play:before {
  content: "\ea1c";
}
.icon-pause:before {
  content: "\ea1d";
}
.icon-stop:before {
  content: "\ea1e";
}
.icon-key:before {
  content: "\e98d";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-attachment:before {
  content: "\e9cd";
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-star-empty:before {
  content: "\e9d7";
}
.icon-star-half:before {
  content: "\e9d8";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-share:before {
  content: "\ea82";
}
.icon-home:before {
  content: "\e900";
}
.icon-globe:before {
  content: "\e9c9";
}
.icon-earth:before {
  content: "\e9ca";
}
.icon-pin-marker:before {
  content: "\e947";
}
.icon-location:before {
  content: "\e948";
}
.icon-compass:before {
  content: "\e94a";
}
.icon-map:before {
  content: "\e94c";
}
.icon-like-cantained:before {
  content: "\e914";
}
.icon-download:before {
  content: "\e90b";
}
.icon-done:before {
  content: "\e90e";
}
.icon-plus:before {
  content: "\e911";
}
.icon-three-dots:before {
  content: "\e916";
}
.icon-building:before {
  content: "\e901";
}
.icon-settings:before {
  content: "\e917";
}
.icon-statistics:before {
  content: "\e903";
}
.icon-user1:before {
  content: "\e904";
}
.icon-arrow-down:before {
  content: "\e905";
}
.icon-arrow-right:before {
  content: "\e906";
}
.icon-bag:before {
  content: "\e907";
}
.icon-bell:before {
  content: "\e908";
}
.icon-calendar:before {
  content: "\e909";
}
.icon-checkbook:before {
  content: "\e90a";
}
.icon-cross:before {
  content: "\2612";
}
.icon-dashboard1:before {
  content: "\e90c";
}
.icon-file:before {
  content: "\e90d";
}
.icon-hummer:before {
  content: "\1f528";
}
.icon-like:before {
  content: "\e90f";
}
.icon-long-arrow-right:before {
  content: "\e910";
}
.icon-megaphone:before {
  content: "\1f56b";
}
.icon-pen:before {
  content: "\e912";
}
.icon-search:before {
  content: "\e913";
}
.icon-star:before {
  content: "\2605";
}
.icon-trash:before {
  content: "\e915";
}
