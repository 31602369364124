.root {
  width: 100%;
  overflow: auto;
}

.menuContainer {
  flex: 1 0 203px;
}

.menu {
  padding: 12px 0;
  flex: 1 0;
}

.bodyContainer {
  flex: 1 1 100%;
  overflow: auto;
}
