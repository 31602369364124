@import '@root/theme/size.scss';
@import "./ChipColor.module.scss";

// colors
$mainColor: var(--btn-main-color);
$contrastColor: var(--btn-contrast-color);
$focusBorderColor: var(--btn-focus-border-color);
$focusMainColor: var(--btn-focus-main-color);
$activeMainColor: var(--btn-active-main-color);
$hoverMainColor: var(--btn-hover-main-color);
$disabledMainColor: var(--btn-disabled-main-color);
$disabledContrastColor: var(--btn-disabled-contrast-color);

.root {
  display: inline-flex;
  padding: $p02 0;
  border-radius: $p08;
  width: min-content;
  border: $p02 solid transparent;
  background: transparent;
  box-sizing: border-box;
  max-width: 125px;
}

.root.rounded {
  border-radius: $p24;
  padding: $p06;
  border-width: $p01; 
}

.label {
  user-select: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: $p12;
  line-height: $p18;
  padding-inline: $p06;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.root > *:not(.label) {
  font-weight: bold;
  font-size: $p12;

  &:first-child {
    margin-left: 5px;
  }
  &:last-child {
    margin-right: 5px;
  }
  &[aria-disabled=true] {
    cursor: default;
    pointer-events: none;
  }
}

.type-contained {
  color: $contrastColor;
  background-color: $mainColor;
  border-color: $mainColor;
  &.clickable:focus-visible {
    background-color: $focusMainColor;
    border-color: $focusBorderColor;
  }
  &.clickable:active {
    background-color: $activeMainColor;
    border-color: $activeMainColor;
  }
}

.type-outlined {
  color: $mainColor;
  background-color: transparent;
  border-color: $mainColor;
  &.clickable:focus-visible {
    color: $focusMainColor;
    border-color: $focusBorderColor;
  }
  &.clickable:active {
    color: $activeMainColor;
    border-color: $activeMainColor;
  }
}

.root[aria-disabled=true] {
  background-color: $disabledMainColor;
  border-color: $disabledMainColor;
  color: $disabledContrastColor;
}