@import 'src/theme/colors.scss'

$gutterSizes: 0 , 4 , 8 , 12 , 16 , 20 , 24 , 32
$flexDirection: 'row' , 'column'
$flexAlign: 'flex-start' , 'center' , 'flex-end' , 'stretch'
$flexJustify: 'flex-start' , 'center' , 'flex-end' , 'space-between'

.root
  display: flex
  flex: 1 1 auto

  @each $gutter in $gutterSizes
    &.gutter-#{$gutter}
      gap: #{$gutter}px

  @each $direction in $flexDirection
    &.direction-#{$direction}
      flex-direction: #{$direction}

  @each $align in $flexAlign
    &.align-#{$align}
      align-items: #{$align}

  @each $justify in $flexJustify
    &.justify-#{$justify}
      justify-content: #{$justify}

  &.wrappable
    flex-wrap: wrap
