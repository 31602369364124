.row {
  display: grid;
  grid-template-columns: 1fr 100px 85px;
  grid-column-gap: 35px;
  padding: 9px 21px 12px 23px !important;
  align-items: center;
}

// .buttonWithIcon {
//   padding: 0 19px;
//   margin: auto;
//   & i {
//     font-size: 17px;
//   }
// }

.gridTemplate {
  grid-template-columns: 1fr 100px;
}

.textRight {
  text-align: right;
}

.actions {
  display: flex;
  justify-content: center;
}