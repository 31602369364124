.root {
  width: "200px";
  font-family: Roboto, Helvetica, sans-serif;
}

.body {
  padding: 30px 10px;
}

.footer {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.3px;
  color: #5f6368;
  padding: 10px 10px;
}
