.root {
  max-width: 100%;
  height: auto;
}

.headerContainer {
  padding: 5px 12px;
  padding-right: 18px;
  padding-bottom: 8px;
  align-items: center;
}

.noFoundMessage {
  margin-block: 30px;
}