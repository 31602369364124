@import "@root/theme/utils.scss";

.root {
  display: flex;
  flex-direction: column;
}

.body {
  flex: 1 1 100%;
}
