@import "@root/theme/colors.scss";
@import "./ButtonSize.module.scss";

$paddingBlock: var(--btn-padding-block);
$paddingInline: var(--btn-padding-inline);

.root {
  font-size: $p18;
  & > * {
    font-weight: 500;
    min-height: 18px;
    min-width: 18px;
  }
}

.contained {
  padding-block: $paddingBlock;
  padding-inline: $paddingBlock;
}

.outlined {
  padding-block: $paddingBlock;
  padding-inline: $paddingBlock;
}

.ghost {
  padding-block: $paddingBlock;
  padding-inline: $paddingBlock;
}

