@import "@root/theme/colors.scss";

.root {
  display: flex;
  user-select: none;
  align-items: center;
  color: gray;
  font-size: 14px;
}

.item {
  display: flex;
  cursor: pointer;
}
