@import '@root/theme/colors.scss';

.row {
  display: flex;
  overflow: visible;
  padding: 0;
}

.column {
  display: flex;
  align-items: center;
  user-select: none;
  gap: 10px
}

.wrapper {
  display: grid;
  grid-template-columns: 40% 1fr 30% 1fr;
  gap: 30px;
  width: 100%;
  padding: 10px;
  align-items: center;
  box-sizing: border-box;
}

.badge {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  height: 24px;
  width: 24px;
}

.sortArrow {
  min-width: 15px;
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
  margin-top: 3px;
}

.sortable {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}