.root {
  width: inherit;
  padding: 1em 5px;
  padding: 5px;
}

.fatTable {
  padding: 11px 15px;
  padding-right: 10px;
}

.tip {
  margin-right: 10px;
}

.header {
  padding-left: 15px;
  margin-bottom: 10px;
}

.linkButton {
  font-size: inherit;
  color: inherit !important;
  padding: 0 !important;
  text-decoration: underline;
}
