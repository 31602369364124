@import "@root/theme/utils.scss"

.root
  display: flex
  flex-direction: column
  width: 100%
  height: min(620px, calc(100vh - 200px))
  gap: $p24

.header

.body
  flex: 1 1 100%
  overflow: auto
