
.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 300px;
  padding: 40px 30px
}

.logo {
  margin: 30px;
}

