.header {
  display: flex;
  flex-direction: column;
  gap: 10px
}

.listBox {
  padding: 12px 0;
  flex: 1 0 315px;
}

.bodyBox {
  padding: 24px;
  flex: 1 1 100%;
}

.menuLabel {
  padding-inline: 25px;
  margin-bottom: 8px;
}

.label {
  display: flex;
  align-items: center;
  gap: 10px;
  user-select: none;
  cursor: pointer;
}