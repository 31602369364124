@import '@root/theme/utils';

.assetsWrapper {
    background-color: $white100Color;
    padding: 24px;
    border-radius: 10px;
    box-shadow: $primaryShadowL1;
    margin-bottom: 24px
}
.progressSubsection {
    border-radius: 10px;
    background-color: $assetsProgressBgColor;
    padding: 18px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.progressCircle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-right: 70px;
}
.listingTitle {
    margin-bottom: 16px;
}
.list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.listItem {
    min-width: 140px;
}

.assetsSections {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
 }

.collapsibleHeader {
    width: 100%;
}
.assetSectionTitle {
    margin-bottom: 10px;
}
.textIcon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.buttonIcon {
    padding: 10px 0 20px 0;
}
.inputsSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 10px;
}
.textField {
    margin-bottom: 10px;
}
.finalUrl {
    min-width: 270px;
    margin-bottom: 10px;
}
.inputUrl {
    margin-top: 16px;
}
.section {
    width: 270px;
    padding: 16px;
    border: 1px solid $bgColor;
    border-radius: 4px;
    margin-bottom: 8px;
}
.images {
    margin: 20px 0 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: $p08;
}
.headTitle {
    margin-bottom: 10px;
}
.videoPreviews {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: $p08;
    margin: 20px 0 20px 0;
}
.videoUrlField {
    flex-grow: 2;
}
.image {
    height: 60px !important;
    width: 60px !important;
}
.videoImage {
    height: 100px !important;
    width: 100px !important;
    margin-bottom: 10px;
}
.videoEdit {
    margin-bottom: 40px;
}
.input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 10px;
}
.tabBody {
    background-color: $secondaryDarkColor;
    padding: 24px;
}
.previewWrapper {
    flex-grow: 3;
    margin-left: 16px;
}
.previewIcons {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px;
}
.previewTitle {
    padding: 0 0 8px 0;
    text-align: center;
}
.previewInfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    margin: 8px 24px 10px;
    border-radius: 8px;
    background-color: $lightGray;
}
.previewIcon {
    font-size: 1.3em;
    margin-right: 10px;
}

.audienceSignalWrap {
    padding: 24px;
    border-radius: 10px;
    box-shadow: $primaryShadowL1;
}
.audienceSignalTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
}
.audienceSignalItem {
    border: 1px solid $bgColor;
    border-radius: 4px;
    padding: 16px;
    margin: 16px 0;
}
.audienceSignalItemInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.audienceSignalSwitch {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.audienceSignalInfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: $assetsProgressBgColor;
    border-radius: 8px;
    padding: 16px;
}
.audienceSignalInfoIcon {
    margin-right: 8px;
}
.assetGroupNameWrap {
    background-color: $white100Color;
    padding: 24px;
    border-radius: 10px;
    box-shadow: $primaryShadowL1;
    margin-bottom: 24px;
    border: 1px solid $bgColor;
}
.assetGroupName {
    width: 300px;
}
.assetGroupNameInput {
    margin-top: 16px;
}
.businessNameInput {
    margin-top: 16px;
}
