@import "@root/theme/colors.scss";

.root {
  position: relative;
  width: 100%;
  min-width: 280px;
  height: 39px;
  font-family: Poppins;
  outline: none;
  cursor: pointer;
}

.input::-webkit-calendar-picker-indicator {
  display: none;
}

.input[type="date"]::-webkit-input-placeholder {
  visibility: hidden !important;
}

.input {
  color: $grayTextColor;
  font-family: Poppins;
  outline: none;
  font-size: 14px;
  line-height: 21px;
  box-sizing: border-box;
  width: 100%;
  padding: 7px 45px 7px 14px;
  border: 1px solid $secondaryDarkColor;
  border-radius: 10px;
  background-color: $lightTextColor;
}

.input:disabled {
  color: $grayTextColor;
}

.input.invalid {
  border-color: $redTextColor;
  color: $redTextColor;
}

.wrapper {
  z-index: 0;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 10px;
  background-color: transparent;
}

.arrow {
  right: 0;
  top: 0;
  padding: 10px 15px;
  position: absolute;
  color: $primaryDarkColor;
  pointer-events: all;
}

.active {
  .input {
    border: none;
    border-radius: 10px 10px 0 0;
    border-color: $lightTextColor;
  }
  & > .wrapper {
    z-index: 2;
    background-color: $lightTextColor;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  }
  .arrow {
    color: $primaryLightColor;
  }
}

.calendar {
  min-width: 260px;
  padding: 8px;
}