.root {
  display: flex;
  justify-content: flex-end;

  & > div {
    width: 100%;
    max-width: 278px;
  }
}

.button {
  margin-left: 10px;
}

.labelsWrapper {
  width: auto;
  display: grid;
  padding-right: 40px;
  grid-template-columns: auto auto;
}
