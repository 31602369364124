@import "@root/theme/colors.scss";
@import "@root/theme/size.scss";

.root {
  display: block;
  font-style: normal;
  font-family: Poppins;
  white-space: pre-line;
  overflow-wrap: break-word;
  cursor: inherit;
}

.root.upperCase {
  text-transform: uppercase;
}

.root.inline {
  display: inline;
  i {
    display: inline;
  }
}

.root.h1 {
  font-size: $p24;
  line-height: $p36;
  font-weight: 500;
}
.root.h2 {
  font-size: $p18;
  line-height: $p27;
  font-weight: 500;
}
.root.h3 {
  font-size: $p16;
  line-height: $p24;
  font-weight: 500;
}
.root.h4 {
  font-size: $p14;
  line-height: $p21;
  font-weight: 500;
}
.root.h5 {
  font-size: $p12;
  line-height: $p18;
  font-weight: 500;
}
.root.h6 {
  font-size: $p10;
  line-height: $p15;
  font-weight: 500;
}

// deprecated
.root.title {
  font-size: 30px;
  line-height: 45px;
}

// deprecated
.root.subtitle {
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
}

.root.subhead1 {
  font-size: $p10;
  line-height: $p15;
  font-weight: 500;
  font-style: normal;
}

.root.subhead2 {
  font-size: $p08;
  line-height: $p12;
  font-weight: 600;
  font-style: normal;
}

.root.body1 {
  font-size: $p14;
  line-height: $p21;
  font-weight: 400;
}

.root.body2 {
  font-size: $p12;
  line-height: $p18;
  font-weight: 400;
}

.root.caption1 {
  font-size: $p14;
  line-height: $p21;
  font-weight: 500;
}

.root.caption2 {
  font-size: $p12;
  line-height: $p18;
  font-weight: 500;
}

.root.caption3 {
  font-size: $p10;
  line-height: $p15;
  font-weight: 400;
}

.root.inherit {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}

.root.weight-normal {
  font-weight: 400;
}

.root.weight-semibold {
  font-weight: 500;
}

.root.weight-bold {
  font-weight: 700;
}

.root.bold {
  font-weight: bold;
}