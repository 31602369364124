@import "@root/theme/colors.scss";
@import "@root/theme/size.scss";

.filterMenusWrapper {
    position: relative;
}
.filterMenu, .metricsMenu, .sortByMenu, .groupByMenu {
    border: 1px solid $primaryDarkColor;
    border-radius: 8px;
    padding: 12px;
    box-shadow: 0 4px 15px 0 rgba(32, 32, 32, 0.1);
    background-color: $white100Color;
    z-index: 2;
}
.filterMenu {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.filterMenuSelects {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.sortByMenu {
    position: absolute;
    min-width: auto;
    top: 40px;
}
.metricsMenu {
    position: absolute;
    min-width: auto;
    top: 40px
}
.groupByMenu {
    position: absolute;
    min-width: auto;
    top: 40px
}
.select {
    min-width: 200px;
}
.filterSelect {
    margin-left: 10px;
    min-width: 200px;
}
.selectInput {
    margin-bottom: 10px;
}
.filteredBy {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    padding: 12px 12px 0;
    margin: 12px 0 0;
    border-radius: 8px;
    border: 1px solid $primary20Color;
    background: $primary05Color;
}
.filterChip {
    margin-right: 12px;
    margin-bottom: 12px;
    max-width: none;
}
.filtersCountIcon {
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: $primaryDarkColor;
    border-radius: 50%;
}
.filterButton {
    position: relative;
}
