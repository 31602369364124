.root {
  text-align: center;
  min-width: 360px;
  max-width: 440px;

  & > * {
    margin-bottom: 16px;
  }
}

.title {
  margin-bottom: 24px;
}

.control {
  margin: auto;
  margin-top: 24px;
}
