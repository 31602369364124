.root {
}

.header {
  display: flex;
  align-items: center;
  margin-top: 51px;
  margin-bottom: 20px;

  & > div:first-child {
    margin-right: 16px;
  }
}

.circleButton {
  border-radius: 50%;
  padding: 0;
  width: 22px;
  height: 22px;
}

.body {
  display: grid;
  grid-template-columns: 50% auto;
  gap: 20px;
}

.section {
  margin: 15px 0 54px 0;
}
