@import "@root/theme/colors.scss"
@import "@root/theme/size.scss"

.root
  display: flex
  align-items: center
  overflow: hidden
  font-size: $p12
  line-height: $p18

  .label
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
