@import '@root/theme/utils.scss';

.root {
  width: 100%;
}

.section {
  font-size: $p12;
  padding: 0 $p14;
  width: 100%;
}
