@import "@root/theme/colors.scss"
@import "@root/theme/size.scss"

.root
  display: flex
  flex-direction: column
  gap: $p08
  background: $white100Color
  padding: 12px
  border: 1px solid transparent
  cursor: pointer
  overflow: hidden

.root.clickable:hover
  background: $white100Color
  border-color: $primary40Color

.root.clickable:active,
.root.clickable.active
  border-color: $primary100Color

.root.disabled
  pointer-events: none
  cursor: not-allowed
  opacity: 0.5

.metricHeader
  min-height: 26px

.metricName
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  max-width: 140px

.metricsContainer
  margin-top: $p08

.root.clickable:hover .link
  text-decoration: underline

.reportCardTitle
  display: flex
  flex-direction: row
  align-items: center
  justify-content: flex-start
  .reportCardTitleBadge
    max-width: 25px
  .reportCardTitleLink
    max-width: 80%

.metricWrap
  position: relative
  &:hover
    .metricPopupHelper
      display: block


.metricPopupHelper
  display: none
  position: absolute
  top: 0
  right: 0
  z-index: 20
  background: $black80Color
  border-radius: 4px
  padding: 5px 10px

// preview image
.previewVideo
  position: relative
  .previewVideoIcon
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: rgba(0,0,0,0.3)
    display: flex
    align-items: center
    justify-content: center

.previewContainer
  width: 100%
  height: 200px
  border-radius: $p08
  overflow: hidden
  background-color: $black20Color
  background-size: 100% 100%
  position: relative

  .views
    display: flex
    align-items: center
    position: absolute
    bottom: $p08
    right: $p08
    gap: $p08
    padding: $p04 $p08
    border-radius: $p04
    background: $black60Color
    i
      font-size: 1.25em

  .image
    width: 100%
    height: 100%

// metrics

.metricChip
  border-radius: $p08
  padding: $p04 $p08
