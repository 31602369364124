@import "@root/theme/utils.scss"

.root
  display: flex
  flex-direction: column
  height: min(620px, calc(100vh - 200px))
  gap: $p24

.header
  display: flex
  flex-direction: column
  gap: $p08

.body
  display: flex
  flex-direction: column
  flex: 1 1 100%
  overflow: auto
  padding-right: $p10
  @include thinScrollbar('&')

.placeholder
  margin: auto
  width: 500px

.grid
  display: grid
  grid-template-columns: repeat(2, 1fr)
  gap: $p16
