@import "@root/theme/colors.scss";

.root {
  width: 100%;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.row {
  padding: 10px 0;
  min-height: 56px;
  display: flex;
}

.header {
  display: flex;
}

.header,
.body {
  position: relative;
  padding: 0 20px;
}

.item {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.item:first-child {
  flex: 2 1;
  font-size: 18px;
  font-weight: bold;
  text-align: start;
}

.clickable {
  cursor: pointer;
}

.openButton {
  padding: 10px;
  position: absolute;
  font-size: 18px;
  cursor: pointer;
  right: 10px;
  top: 20px;
}

.openButton:hover {
  color: $grayTextColor;
}
