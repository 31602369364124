@import "@root/theme/colors.scss";

$duration: 8s;

.root {
  display: block;
  height: 3px;
  margin: 0;
  width: 100%;
  background-image: linear-gradient(
    -45deg,
    $primaryDarkShadow 25%,
    $primaryLightColor 25%,
    $primaryLightColor 50%,
    $primaryDarkShadow 50%,
    $primaryDarkShadow 75%,
    $primaryLightColor 75%,
    $primaryLightColor
  );
  background-size: 30px 30px;
  height: 3px;

  animation: none;
  opacity: 0;
  transition: opacity 0.3s;
}

.visible {
  transition: opacity 0.1s;
  opacity: 1;
  -webkit-animation: loading $duration infinite linear;
  -moz-animation: loading $duration infinite linear;
  -ms-animation: loading $duration infinite linear;
  -o-animation: loading $duration infinite linear;
  animation: loading $duration infinite linear;
}

.variant-small {
  margin-block: 5px 4px;
}

.variant-medium {
  margin-block: 11px 10px;
}

.variant-big {
  margin-block: 15px 14px;
}

@keyframes loading {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@-moz-keyframes loading {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -100% 0;
  }
}

@-webkit-keyframes loading {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -100% 0;
  }
}

@-ms-keyframes loading {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -100% 0;
  }
}

@-o-keyframes loading {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -100% 0;
  }
}
