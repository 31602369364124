.root {
  padding-bottom: 90px;
}

.body {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  width: 100%;
  padding: 40px 0 90px 0;
  height: max-content;
}
