.root {
  width: 100%;
  height: max-content;
}

.field {
  width: 250px;
}

.shortRow {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  z-index: 1;
}

.rowButton {
  align-self: flex-end;
  max-height: 40px;
}
