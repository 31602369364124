@import '@root/theme/utils.scss';

.root {
  display: flex;
  padding-block: $p06;
  cursor: pointer;
}

.label:first-child {
  margin-right: $p08;
}

.label:last-child {
  margin-left: $p08;
}

.orientation-vertical {
  flex-direction: column;
  align-items: flex-start;
}

.orientation-horizontal {
  flex-direction: row;
  align-items: center;
}