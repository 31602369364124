@import "@root/theme/colors.scss";

.section {
  &:not(:last-child) {
    border-bottom: 1px solid #c4c4c4;
  }
  padding: 8px 0;
}

.wrapHeader {
  padding: 0;
  justify-content: space-between;
  &:hover {
    color: $primaryLightColor;
  }
}

.wrapTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 0;
}

