@import "@root/theme/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-width: 600px;
  padding: 20px 50px;
  cursor: pointer;
}

.row,
.header {
  gap: 30px;
  display: flex;
}

.header {
  padding-left: 94px;
  padding-right: 40px;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
  max-height: 300px;
  padding: 20px;
  padding-top: 5px;
  overflow: auto;
}

.column {
  flex: 1 1;
  display: flex;
  align-items: center;
}

.footer {
  display: flex;
  justify-content: center;
}

.error {
  margin-bottom: 10px;
  text-align: center;
}
