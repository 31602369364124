.root {
  display: flex;
  flex-direction: column;
  align-items: center;

  & button {
    width: 184px;
    margin-top: 19px;
  }
}

.title {
  text-align: center;
  margin-bottom: 10px;
}