@import "@root/theme/utils.scss";

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 450px;
}

.header {
  padding: $p32;
}

.footer {
  padding: $p24 $p32;
}

.body {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  overflow: auto;
  padding: 0 $p32;
}

.footer {
  box-shadow: 0px -4px 12px rgba(233, 236, 246, 0.8);
}

.navContainer {
  margin-bottom: 24px;
}

.bodyContainer {
  margin-right: -$p18;
  padding-right: $p12;
}
