.root {
}

.wrapper {
  display: flex;
  align-items: center;
  margin-top: 51px;

  & > div:first-child {
    margin-right: 16px;
  }
}

.circleButton {
  border-radius: 50%;
  padding: 0;
  width: 22px;
  height: 22px;
}

.row {
  display: grid;
  grid-template-columns: 1fr 100px 85px;
  grid-column-gap: 35px;
  padding: 9px 21px 12px 23px !important;
  align-items: center;
}

.container {
  & > div:not(:last-child) {
    margin-bottom: 10px;
  }
}

.characterCountInput {
  width: 65px;
}

.title {
  margin-bottom: 18px;
}

.gridTemplate {
  grid-template-columns: 1fr 100px;
}

.footer {
  margin-top: 18px;
}

.button {
  padding: 0;
}

.textRight {
  text-align: right;
}

.textCenter {
  text-align: center;
}
