@import '@root/theme/utils.scss';

.root {
  padding: $p20 $p24;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  width: 100%;
  &, & > * {
    cursor: pointer;
  }
}

.title {
  display: flex;
  align-items: center;
  gap: 10px;
  i {
    font-size: 1.6em;
  }
}

.table {
  font-size: $p12;
}

.input {
  width: 280px;
}