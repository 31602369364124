@import "@root/theme/colors.scss";

.row {
  display: flex;
  align-items: center;
  gap: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  user-select: none;
  flex: 1 1 auto;
  cursor: pointer;
  &:hover {
    color: $primaryLightColor;
  }
}

.wrapContainer {
  padding-left: 24px;
  gap: 0;
}
