@import "@root/theme/colors.scss";

.root {
  position: relative;
  overflow: hidden;
  width: fit-content;
  height: fit-content;
}

.wrapper {
  width: 100%;
  height: fit-content;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.overlay {
  display: block;
  position: fixed;
  top: 0px;
  left: 0px;
  animation: reveal 0.3s linear;
  opacity: 1;
  background-color: $lightTextColor;
  font-size: 24px;
  z-index: 1;
  padding: 7px 7px;
  transform: translate(-7px, -7px);
  box-shadow: 2px 3px 3px 0px rgba(200, 200, 200, 0.3);
  border-radius: 10px;
  box-sizing: border-box;
  word-wrap: break-word;
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  0% {
    opacity: 1;
  }
}
