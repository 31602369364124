@import "@root/theme/colors.scss";

.root {
  display: flex;
  box-sizing: border-box;
  padding: 0 60px;
  margin: 0 auto;
  width: 1520px;
  min-width: 1520px;
}

.embedded {
  flex: 1 1 100%;
  overflow: auto;
  flex-direction: column;
}

@media (max-width: 1700px) {
  .root {
    width: 100%;
    min-width: 1200px;
    max-width: 1450px;
    padding: 0 30px;
  }
}
