@import '@root/theme/utils.scss';

.root {
  width: 100%;
  border: none;
  border-spacing: initial;
  position: relative;
  * {
    box-sizing: border-box;
  }
}

.row {
  display: grid;
  grid-template-columns: 1fr repeat(5, 135px);
}

.col {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #EAEAEA;
  padding: $p08;
  overflow: hidden;
  word-break: break-word;
}
