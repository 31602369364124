@import "@root/theme/utils.scss"

.root
  display: flex
  align-items: center
  gap: $p16
  width: 100%
  max-width: 100%
  box-sizing: border-box
  min-height: $p40

.left,
.right
  display: flex
  flex-wrap: wrap
  align-items: center
  flex: 1 1 auto
  gap: $p16
  min-height: 100%

.left
  justify-content: flex-start

.right
  justify-content: flex-end

.offset-none
  margin-bottom: 0
.offset-small
  margin-bottom: $p12
.offset-medium
  margin-bottom: $p24
.offset-big
  margin-bottom: $p45

.height-auto
  min-height: auto
.height-small
  min-height: $p24
.height-medium
  min-height: $p36
.height-big
  min-height: $p40
.height-huge
  min-height: 48px

.clickable
  cursor: pointer
