.title {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 8px 0;
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
}
