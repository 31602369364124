@import "@root/theme/utils.scss";

.root {
  padding-left: $p40;
  padding-right: $p06;
  position: relative;
  overflow-y: scroll;
  @include ghostScrollbar('&')
}

.tableRoot {
  --label-width: 400px;
}

.checkedRowContainer {
  display: flex;
  .checkbox {
    position: absolute;
    left: 0;
    margin: 7px 0;
  }
}
