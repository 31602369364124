@import '@root/theme/colors.scss';

.root {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
}

.root > input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  cursor: pointer;
  position: absolute;
  inset: 0;
  background: $black20Color;
  transition: background-color .2s;
}

input + .slider:before {
  content: "";
  position: absolute;
  left: 2px;
  bottom: 2px;
  background: $white100Color;
  border-radius: 50%;
  transition: transform .4s;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
}

input:checked {
  & + .slider {
    background: $primary100Color;
    &::before {
      background: $black05Color;
    }
  }
  & + .slider:before {
    transform: translateX(16px);
    &::before {
      background: $white100Color;
    }
  }
}

input:focus-visible + .slider {
  background: $black10Color;
  outline: solid $success100Color;
  &::before {
    background: $black03Color;
  }
}
input:focus-visible:checked + .slider {
  background: $primary60Color;
  &::before {
    background: $white100Color;
  }
}

input:hover + .slider {
  background: $black10Color;
  &::before {
    background: $black03Color;
  }
}
input:hover:checked + .slider {
  background: $primary120Color;
  &::before {
    background: $white100Color;
  }
}

input:active + .slider {
  background: $black30Color;
  &::before {
    background: $black10Color;
  }
}
input:active:checked + .slider {
  background: $primary140Color;
  &::before {
    background: $white100Color;
  }
}

input:disabled + .slider {
  background: $black05Color;
  &::before {
    background: $black20Color;
  }
}
input:disabled:checked + .slider {
  background: $black10Color;
  &::before {
    background: $black03Color;
  }
}

.root.small {
  height: 16px;
  width: 32px;
  .slider {
    border-radius: 32px;
  }
  .slider:before {
    height: 12px;
    width: 12px;
  }
}

.root.big {
  height: 20px;
  width: 36px;
  .slider {
    border-radius: 36px;
  }
  .slider:before {
    height: 16px;
    width: 16px;
  }
}