@import "@root/theme/colors.scss";

$GScale: 5px;

@keyframes gradient {
	0% {
    background-position: 0 0;
	}
	100% {
    background-position: $GScale * 100 0;
	}
}

.root {
  width: 100%;
  background-color: $bgColor;
  overflow: hidden;
  position: relative;
}

.gradient {
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(90deg, #17899E 0, #92CBD6 $GScale * 12.5, #FFFFFF $GScale * 37.5, #93D692 $GScale * 62.5, #5CBD9F $GScale * 87.5, #17899E $GScale * 100);
  background-size: $GScale * 100;
  animation: gradient 2.5s linear infinite;
  filter: blur(3px);
}

.bar {
  width: 100%;
  height: 100%;
  transition: width var(--bar-transition-speed, 0.7s) linear;
  overflow: hidden;
}

.root.tiny {
  height: 7px;
  border-radius: 0;
  & > .bar {
    background-color: #086172;
    border-radius: 0;
  }
}

.root.small {
  height: 15px;
  border-radius: 30px;
  & > .bar {
    border-radius: 30px;
  }
}

.root.medium {
  height: 21px;
  border-radius: 30px;
  & > .bar {
    border-radius: 30px;
  }
}

.root.big {
  height: 50px;
  border-radius: 10px;
  & > .bar {
    border-radius: 10px;
  }
}
