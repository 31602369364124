@import "@root/theme/colors.scss";

.header {
  text-align: center;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 17px;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.dataWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  & div:last-child {
    margin-top: 4px;
  }
}

.percent {
  display: flex;
  justify-content: center;
}

.upArrow {
  margin-right: 3px;
  color: $lightGreen;
}

.downArrow {
  margin-right: 3px;
  color: $redTextColor;
}
