@import '@root/theme/utils.scss';

.root {
  width: 100%;
  border: none;
  border-spacing: initial;
  position: relative;
}

.row {
  overflow: hidden;
  align-items: center;
}

.col {
  width: 100%;
  padding-inline: $p12;
  overflow: hidden;
  text-align: start;
  background-color: $white100Color;
}

.root.layoutFixed {
  table-layout: fixed;
}

.root.gutter-big .col{
  padding-block: $p12;
}

.root.gutter-medium .col{
  padding-block: $p09;
}

.root.gutter-small .col{
  padding-block: $p06;
}

.row.hoverable:hover {
  .col {
    background-color: $primary10Color;
  }
}

.row.sticky {
  position: sticky;
  top: 0;
}

.root.withBorder .row:not(:last-child) {
  .col {
    border-bottom: 1px solid #EAEAEA;
  }
}

.root.hideXOffset {
  .col:first-child {
    padding-left: 0;
  }
  .col:last-child {
    padding-right: 0;
  }
}


.col.vAlign-top {
  vertical-align: top;
}

.col.vAlign-middle {
  vertical-align: middle;
}

.col.vAlign-bottom {
  vertical-align: bottom;
}

.col.hAlign-start {
  text-align: start;
}

.col.hAlign-center {
  text-align: center;
}

.col.hAlign-end {
  text-align: end;
}