.header {
  display: flex;
}

.listBox {
  flex: 1 0 315px;
}

.bodyBox {
  flex: 1 1 100%;
}

.menuLabel {
  padding-inline: 25px;
  margin-bottom: 7px;
}

.label {
  display: flex;
  align-items: center;
  gap: 10px;
  user-select: none;
  cursor: pointer;
}