@import '@root/theme/utils';

.assetImageUploadSidebar {
    width: 830px;
    padding: 30px;
    box-sizing: border-box;
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.title {
    margin-bottom: 16px;
}

.sidebarFooter {
    background-color: $white100Color;
    position: fixed;
    bottom: 0;
    height: 84px;
    padding: 24px;
    right: 0;
    left: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: $secondaryShadowL3;
    z-index: 2;
}
.sidebarFooterLeft {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.footerButton {
    margin-left: 20px;
}
.imagesList {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 16px 0 0;
    gap: 24px;
    padding: 0 0 60px;
    //overflow-y: auto;
    //min-height: 400px;
}
.uploadedImagesList {
    position: relative;
}
.loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $black40Color;
    opacity: .4;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.dropZone {
    background-color: $assetImagesBg;
    position: relative;
}

.renameImageModal {
    padding: 25px;
    width: 370px;
}
.deleteImageModal {
    padding: 25px;
    width: 390px;
}
.cropImageModal {
    padding: 25px;
    width: 700px;
}
.cropperWrap {
    height: 400px;
    width: 400px;
    position: relative;
}
.cropperActions {
    margin: 40px 0 0;
}
