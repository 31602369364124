@import '@root/theme/utils.scss'

.alertCard
  display: grid
  grid-template-columns: auto 1fr auto
  align-items: center
  position: relative
  gap: 10px
  width: 100%
  padding: 12px 18px
  margin-right: 60px
  border-radius: 10px
  background: white
  color: var(--message-color, black)
  box-sizing: border-box

.icon
  border-radius: 50%
  font-size: 22px

.colorSuccess
  --message-color: #{$primary100Color}
  background-color: #{$primary10Color}

.colorInfo
  --message-color: #{$yellowBackgroundColor}
  background-color: #f7efdc

.colorError
  --message-color: #{$redBackgroundColor}
  background-color: #FBE0DE

.message
  display: flex
  flex-direction: column
  gap: 2px

.control
  display: flex
  align-items: center
  gap: 5px
  margin: -12px
  margin-left: 0
  & > .button
    font-size: 14px
    padding: 7px 24px 8px 24px
    border: none
