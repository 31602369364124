@import "@root/theme/colors.scss";

.sidebarMenu {}
.menuLabel {
  padding-inline: 25px;
  margin-bottom: 7px;
}
.reportsSection {
  overflow-y: auto;
}
.sidebarList {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sidebarListItem {
  margin: 8px 20px;
  padding: 5px 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    background: $primary20Color;
  }
  &.sidebarListItemClickable {
    justify-content: space-between;
    position: relative;
  }
  &.sidebarListItemActive {
    background: $primary20Color;
  }
}
.sidebarListItemLabel {
  margin-left: 10px;
}
.sidebarListItemInner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.menuIcon {
  padding: 4px;
  border-radius: 4px;
  &:hover {
    background: $primary40Color;
  }
}
.actionsMenu {
  background: white;
  border-radius: 8px;
  z-index: 10;
  display: block;
  position: absolute;
  left: 0px;
  bottom: 0;
  box-shadow: 0 4px 15px 0 rgba(32, 32, 32, 0.1);
    &.visible {
        display: block;
    }
}
