@import '../../../../../theme/colors.scss';

.root {
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: underline;
  color: $primaryDarkColor;  
}

.tooltip {
  padding: 16px;
}