.root {
  position: relative;
}

.contextMenu {
  position: absolute;
  font-size: 16px;
  min-width: 100px;
  z-index: 1;
  top: 20px;
  right: 10px;
}
