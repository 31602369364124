@import "@root/theme/colors.scss";

.tooltipContainer {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltipContent {
  position: absolute;
  padding: 8px;
  background-color: $lightGray;
  border-radius: 4px;
  font-size: 12px;
  pointer-events: none;
  z-index: 10;
  box-shadow: 2px 3px 3px 0px rgba(200, 200, 200, 0.3);

  &::after {
    content: '';
    position: absolute;
    border-style: solid;
  }

  &.tooltip-top {
    transform: translateX(-50%);
    margin-bottom: 8px;

    &::after {
      top: 100%;
      left: 50%;
      border-width: 5px 5px 0;
      border-color: $lightGray transparent transparent transparent;
      border-left-width: 5px;
      border-right-width: 5px;
      transform: translateX(-50%);
    }
  }

  &.tooltip-bottom {
    transform: translateX(-50%);
    margin-top: 8px;

    &::after {
      bottom: 100%;
      left: 50%;
      border-width: 0 5px 5px;
      border-color: transparent transparent $lightGray transparent;
      border-left-width: 5px;
      border-right-width: 5px;
      transform: translateX(-50%);
    }
  }

  &.tooltip-left {
    transform: translateY(-50%);
    margin-right: 8px;

    &::after {
      top: 50%;
      right: 100%;
      border-width: 5px 5px 5px 0;
      border-color: transparent $lightGray transparent transparent;
      border-top-width: 5px;
      border-bottom-width: 5px;
      transform: translateY(-50%);
    }
  }

  &.tooltip-right {
    transform: translateY(-50%);
    margin-left: 8px;

    &::after {
      top: 50%;
      left: 100%;
      border-width: 5px 0 5px 5px;
      border-color: transparent transparent transparent $lightGray;
      border-top-width: 5px;
      border-bottom-width: 5px;
      transform: translateY(-50%);
    }
  }
}
