.defaultText {
  margin: 9px 0;
}

.imageCell {
  width: 180px;
  height: 100px;
}

.link {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: wrap;
  max-width: 250px;
  margin: 9px 0;
}
