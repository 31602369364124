@import "@root/theme/utils.scss";

.root {
  padding: 25px 30px 30px 30px;
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin-top: 16px;
  gap: $p16;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}