@import "@root/theme/utils.scss";

.root {
  overflow: auto;
  max-height: 100%;
  height: 100%;
}

.closeIcon {
  position: absolute;
  font-size: 1rem;
  width: 48px;
  height: 48px;
  border-radius: $p08 0 0 $p08;
  top: $p24;
  right: 100%;
}
