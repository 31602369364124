@import "@root/theme/utils.scss";

.root {
  font-size: $p14;
  font-weight: 400;
  color: $black100Color;

  :global(.selector__control) {
    border-radius: $p08;
    border: 1px solid $black30Color;
  }

  :global(.selector__control--is-focused),
  :global(.selector__control:hover) {
    border-color: $primary100Color;
    box-shadow: none;
  }

  :global(.selector__value-container) {
    padding-block: $p04 $p05;
  }
}
