@import "@root/theme/colors.scss"

.root
  width: 300px
  height: fit-content
  font-family: Poppins
  padding-inline: 12px
  box-sizing: border-box

.input::-webkit-calendar-picker-indicator
  display: none

.input[type="date"]::-webkit-input-placeholder
  visibility: hidden !important

.input
  color: $grayTextColor
  font-family: Poppins
  outline: none
  font-size: 14px
  line-height: 21px
  box-sizing: border-box
  width: 100%
  padding: 7px 45px 7px 14px
  border-radius: 10px
  background-color: $lightTextColor
  border: 1px solid $utilBorder

  &:disabled
    color: $grayTextColor

  &.invalid
    border-color: $redTextColor
    color: $redTextColor

.calendar
  padding: 0
  padding-block: 16px

.control
  display: flex
  gap: 16px
