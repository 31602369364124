.root {
  width: 100%;
  height: max-content;
}

.field {
  width: 270px;
}

.shortRow {
  display: flex;
  padding: 15px 20px;
  column-gap: 30px;
  z-index: 1;
}

.rowButton {
  align-self: flex-end;
  max-height: 40px;
}
