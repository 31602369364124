@import "@root/theme/utils.scss";

.root {
  display: flex;
  align-items: stretch;
  position: relative;
  background: $white100Color;
  border: 1px solid $black30Color;
  padding-block: 0;
  padding-inline: $p15;
  border-radius: $p08;
  font-size: $p14;
  font-weight: 400;
  box-sizing: border-box;
  color: $black100Color;
  width: auto;

  input {
    flex: 1 1;
    border: none;
    background: transparent;
    outline: none;
    padding-inline: 0;
    color: inherit;

    &:not(:first-child) {
      padding-left: $p10;
    }
    &:not(:last-child) {
      padding-right: $p10;
    }
  }
  
  &>*:not(.before):not(.after) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: $p21;
    padding-block: $p08 $p09;
    flex: 1 1 auto;
  }

  *::placeholder {
    font-size: $p14;
    color: $black70Color;
  }

  &:focus-within {
    border-color: $primary100Color;
  }
}

.icon {
  left: 0;
  top: 0;
  height: 100%;
  width: 46px;
  position: absolute;
}

.active {
  border-color: $primary100Color;
}

.valid {
  border-color: $success100Color;
}

.error {
  border-color: $error100Color;
}

.withIcon {
  padding-left: 46px;
}

.after, .before {
  display: flex;
  align-items: center;
  color: $black70Color;
  gap: $p08;
  &>:global(.UIIcon-root) {
    font-size: $p18;
  }

  & > hr, :global(.divider) {
    height: 60%;
    width: $p01;
    max-width: $p01;
    margin-block: auto;
    background: $black70Color;
    border: none
  }
}

.after {
  :global(.UIButton-root) {
    margin-right: -$p12;    
  }
}

.before {
  :global(.UIButton-root) {
    margin-left: -$p12;
  }
}

.root.disabled {
  pointer-events: none;
  background: hsla(0, 0%, 92%, 0.3);
  border-color: $utilBorder;
  cursor: not-allowed;
}

.edited {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  padding: 8px;
  cursor: pointer;
  
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    left: 0;
    width: 5px;
    height: 22px;
    background: $info80Color;
    border-radius: 0 5px 5px 0;
    box-sizing: border-box;
  }
  &:hover::after { 
    background: $info100Color;
  }
}