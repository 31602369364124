@import "@root/theme/colors.scss";

.root {
  width: 100%;
  padding: 40px 0;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 22px;

  & > button {
    margin-right: 10px;
  }

  & > button:last-child {
    margin-right: 0px;
  }

  i {
    color: $primaryLightColor;
  }
}

.button {
  z-index: 0;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
