@import "@root/theme/colors.scss"
@import "@root/theme/size.scss"

.root
  width: min(800px, 100vw)

.section
  background: $primary05Color
  border: 1px solid $primary60Color
  border-radius: $p08
  padding: $p12

.pair
  display: grid
  grid-template-columns: 30% 1fr
  align-items: center
  gap: $p10

.row
  display: flex
  gap: $p10
  &>*
    flex: 1 1 100%

  &>*.short
    flex: 1 1 auto
