.root {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 178px);
  width: 100%;
  margin-top: 40px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.stepper {
  flex-grow: 1;
}

.control {
  display: flex;
  gap: 12px;
}

.buttonsWrapper {
  flex: 1 1;
  padding: 30px 0 102px 0;
  bottom: 10px;
  right: 153px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.nextStepButton {
  padding: 0;
}

.editButton {
  min-width: 88px;
}

.invisible {
  opacity: 0;
  pointer-events: none;
}
