@import "@root/theme/colors.scss";

.root {
  text-align: center;
  width: 94px;
  height: 100%;
  background: linear-gradient(
    180deg,
    $primaryLightColor 0%,
    $primaryDarkColor 100%
  );
  border-bottom-right-radius: 10px;
  box-shadow: 0px 4px 20px $primaryDarkShadow;
}

.header {
  padding-top: 20px;
}

.list {
  list-style-type: none;
  color: $lightTextColor;
  margin: auto;
  margin-top: 40px;
  padding: 0;
}

.item {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 10px;
  font-weight: 400;
  font-size: 10px;
  line-height: 110%;
  cursor: pointer;
}

.item:hover {
  text-shadow: 0px 0px 10px $primaryDarkColor, 0px 0px 20px $primaryDarkColor;
}

.sidebarItem {
  height: 78px;
}

.menuItem {
  padding: 10px 0;
  height: auto;
}

.icon {
  padding: 8px;
  font-size: 25px;
}

.active {
  background: white;
  color: #21919f;
}

.sidebarMenu {
  display: flex;
  width: min-content;
  position: fixed;
  top: 75px;
  left: 94px;
}
