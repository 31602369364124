@import "@root/theme/size.scss";
@import "@root/theme/colors.scss";

.GroupsSidebar {
    width: 850px;
    padding: 30px;
    box-sizing: border-box;
}
.title {
    margin-bottom: 15px;
}
.titleAds {
    margin-top: 10px;
}
.metricsBar {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.metricCards {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    .metricCard {
        flex-basis: 150px;
    }
    min-width: 350px;
}
.adsList {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $p10;
}
.adsListTitle {
    margin-top: 30px;
}
.table {
    height: calc(100vh - 300px);
    max-height: 500px;
    width: 100%;
    overflow: hidden;
    border-radius: $p08;
    background: $white100Color;
    & *::-webkit-scrollbar-track {
        background: $white100Color;
    }
}
