@import '@root/theme/utils';

.assetImageWrap {
    width: 235px;
    height: 235px;
    border: 1px solid $assetImageBorder;
    border-radius: 8px;
    &:hover {
        .imageActions {
            visibility: visible;
        }
        .menuButton {
            visibility: visible;
        }
    }
}
.imageWrap {
    height: 177px;
    text-align: center;
    position: relative;
    cursor: pointer;
}
.image {
    object-fit: contain;
    max-width: 100%;
    height: 100%;
}
.bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: $assetImagesBg;
    height: 58px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 12px;
    box-sizing: border-box;
}

.bottomText {
    max-width: 85%;
}

.nameText {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.imageActions {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $white100Color;
    opacity: .55;
    visibility: hidden;
    z-index: 1;
}
.menuButton {
    visibility: hidden;
    position: absolute;
    top: 10px;
    right: 10px;
    color: $black100Color;
    z-index: 2;
}

.actionsMenu {
    position: absolute;
    padding: 0 10px;
    width: 125px;
    height: 115px;
    border: 1px solid $primaryDarkColor;
    border-radius: 8px;
    z-index: 10;
    background: $white100Color;
    right: 10px;
    top: 32px;
    box-sizing: border-box;
}
.actionsMenuDelete {
    border-top: 1px solid $utilBorder;
}
.loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $assetImagesBg;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
