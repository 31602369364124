@import "@root/theme/colors.scss";

.root {
  display: flex;
  justify-content: space-between;
  padding: 22px 20px 14px 29px;

  & > div:first-child {
    margin-right: 122px;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.item {
  text-align: center;
}

.trend {
  display: flex;
  justify-content: center;
  & > span {
    font-size: 2em;
    line-height: 0.6em;
  }
}

.arrow {
  margin-right: 3px;
}
