@import "@root/theme/colors.scss";

.root {
  display: grid;
  gap: 10%;
  grid-template-columns: 65% auto;
  grid-template-rows: repeat(2, auto);
  padding: 20px 129px 30px 30px
}

.monthsContainer {
  display: grid;
  grid-template-columns: repeat(6, 95px);
  grid-template-rows: repeat(2, 33px);
  gap: 14px;
  z-index: 0;
}

.form {
  display: flex;
  align-items: center;

  & > div:first-child {
    width: 134px;
    height: 37px;
    margin-right: 16px;
  }
}
