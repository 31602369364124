@import "@root/theme/utils.scss"

$borderRadius: $p08

.root
  display: flex
  align-items: stretch
  height: 80px
  &,& *
    box-sizing: border-box

.tip
  display: flex
  align-items: center
  justify-content: center
  font-size: $p24
  width: 72px
  color: $primary100Color
  background: $primary20Color
  border-top-left-radius: $borderRadius
  border-bottom-left-radius: $borderRadius

.body
  padding: 12px
  width: 250px
  background: $white100Color
  border-top-right-radius: $borderRadius
  border-bottom-right-radius: $borderRadius
