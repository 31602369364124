.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-width: 400px;
  padding: 20px 70px;
  cursor: pointer;
}

.body {
  margin-bottom: 35px;
}

.footer {
  display: flex;
  justify-content: center;
}
