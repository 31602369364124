@import '../../../../theme/colors.scss'

.alertCard
  display: grid
  grid-template-columns: auto 1fr auto
  align-items: flex-start
  position: relative
  gap: 12px
  width: 400px
  padding: 20px 16px
  margin-right: 60px
  border-radius: 8px
  border: 1px solid #e0e4f1cc
  background: white
  box-shadow: 0px 6px 20px #d3d5decc

.icon
  border-radius: 50%
  font-size: 22px
  padding: 7px

.colorSuccess
  .icon
    background: #71ffd952
    color: #43c294

.colorInfo
  .icon
    background: #ffe58857
    color: #ffc125

.colorError
  .icon
    background: #fd656594
    color: #FF4A4A

.mantaStyle
  .icon
    background: none
    font-size: 32px
    padding: 3px

.mantaStyle.colorSuccess
  border-color: #6dffc9
  box-shadow: 0px 6px 20px #94b3abb7
  background-color: #effffd

.mantaStyle.colorInfo
  border-color: #f9d374
  box-shadow: 0px 6px 20px #bab29cbc
  background-color: #fffdf6

.mantaStyle.colorError
  border-color: #ffc0c0
  box-shadow: 0px 6px 20px #bc9d9dc7
  background-color: #ffebeb

.message
  display: flex
  flex-direction: column
  gap: 2px

.closeButton
  align-self: center
  height: 36px
