@import '../../../../theme/colors.scss';

.root {
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: underline;
  color: $primaryDarkColor;
  user-select: none;
}

.toolTip {
  padding: 8px;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.arrow {
  margin: 2px 0;
  font-size: 1em;
  color: $primaryDarkColor;  
}