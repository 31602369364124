@import "@root/theme/utils.scss";

.root {
  display: flex;
  flex-direction: column;
  padding: $p16 $p24 $p24;
  overflow: auto;
}

.dropdown {
  width: 250px;
}