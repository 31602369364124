@import "@root/theme/colors.scss";

.box {
  min-width: 250px
}
.button {
  position: relative;
  &:hover .tooltip {
    display: block;
  }
}
.tooltip {
  position: absolute;
  display: none;
  padding: 5px 7px;
  border-radius: 5px;
  left: 0;
  top: 38px;
  background-color: $black10Color;
  opacity: 1;
  z-index: 5;
  box-shadow: 1px 1px 10px -5px $black70Color;
}
