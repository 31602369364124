@import "@root/theme/utils.scss";

.root {
  width: 200px;
  height: 200px;
  overflow: hidden;
  position: relative;
}

.frame {
  fill: transparent;  
  stroke: $primary20Color;
  stroke-width: 10px;
}

.progress {
  width: 100%;
  height: 100%;
  fill: transparent;
  stroke-width: 10px;
  stroke: $primaryDarkColor;
  transition: stroke-dashoffset var(--bar-transition-speed, 0.7s) linear;
}

.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% + 0.2ch), -50%);
}

// TODO

.root.tiny {
  height: 42px; 
  width: 42px;
  .label {
    font-size: 10px;
  }
}

.root.small {
  height: 50px;
  width: 50px;
  .label {
    font-size: 12px;
  }
}

.root.medium {
  height: 100px;
  width: 100px;
  .label {
    font-size: 16px;
  }
}

.root.big {
  height: 150px;
  width: 150px;
  .label {
    font-size: 20px;
  }
}
