@import "@root/theme/index.scss";
@import "@root/theme/colors.scss";

:root {
  font-size: 16px;
}

body {
  overflow-y: auto;
  overflow-x: overlay;
  width: 100%;
  height: 100%;
}

#root {
  width: fit-content;
  min-width: calc(100vw - 0.8em);
  min-height: 100vh;
  height: 100vh;
  background: $pageBg;
}

*::-webkit-scrollbar {
  width: 0.8em;
  height: 0.8em;
}

*::-webkit-scrollbar-track {
  background: $pageBg;
  // box-shadow: inset 0 0 6px rgba(95, 95, 95, 0.3);
}

*::-webkit-scrollbar-thumb {
  background: linear-gradient(
    90deg,
    $primaryLightColor 0%,
    $primaryDarkColor 100%
    );
  border-radius: 10px;
}

:root {
  // shadows
  --primary-shadow-l1: 0px 4px 10px rgba(26, 113, 168, 0.102);
  --primary-shadow-l2: 0px 4px 20px rgba(26, 115, 169, 0.15);
  --primary-shadow-l3: 0px 4px 20px rgba(26, 115, 169, 0.2);

  --secondary-shadow-l1: 0px 4px 15px rgba(32, 32, 32, 0.1);
  --secondary-shadow-l2: 0px 4px 20px rgba(32, 32, 32, 0.15);
  --secondary-shadow-l3: 0px 4px 20px rgba(32, 32, 32, 0.2);

  // color palette
  --white-100-color : #fff;

  --black-100-color : #212121;
  --black-90-color : #373737;
  --black-80-color : #4d4d4d;
  --black-70-color : #646464;
  --black-60-color : #6D7175;
  --black-40-color : #9c9ea2;
  --black-30-color : #B7B9BB;
  --black-20-color : #CBCCCE;
  --black-10-color : #E3E3E4;
  --black-05-color : #EEEFEF;
  --black-03-color : #F8F8F8;

  --primary-140-color : #005B70;
  --primary-120-color : #007287;
  --primary-100-color : #17899E;
  --primary-80-color : #45A1B1;
  --primary-60-color : #74B8C5;
  --primary-40-color : #A2D0D8;
  --primary-20-color : #D1E7EC;
  --primary-10-color : #E8F3F5;
  --primary-05-color : #F3F9FA;

  --secondary-200-color : #b5b8bf;
  --secondary-140-color : #b5b8bf;
  --secondary-120-color : #CCCED4;
  --secondary-100-color : #E1E3E9;
  --secondary-80-color : #E7E9ED;
  --secondary-60-color : #EDEEF2;
  --secondary-40-color : #F3F4F6;
  --secondary-20-color : #F9F9FB;
  --secondary-10-color : #fcfcfc;

  --success-120-color : #26A275;
  --success-100-color : #18B560;
  --success-80-color : #51B591;
  --success-60-color : #7DC7AC;
  --success-40-color : #A8DAC8;
  --success-20-color : #D4ECE3;

  --info-120-color : #e8a136;
  --info-100-color : #FFBC57;
  --info-80-color : #ffc876;

  --error-120-color : #CB483F;
  --error-100-color : #E9635A;
  --error-80-color : #ED827B;
  --error-40-color : #F6C0BD;
  --error-20-color : #FBE0DE;

  //--metric-reverted_0-20_color: #A8DAC8;
  //--metric-reverted_20-40_color: #7DC7AC;
  //--metric-reverted_40-60_color: #E3E3E4;
  //--metric-reverted_60-80_color: #FBE0DE;
  //--metric-reverted_80-100_color: #F6C0BD;

  --metric-reverted_0-10_color: rgba(125, 199, 172, 1);
  --metric-reverted_10-20_color: rgba(125, 199, 172, .8);
  --metric-reverted_20-30_color: rgba(125, 199, 172, .6);
  --metric-reverted_30-40_color: rgba(125, 199, 172, .4);
  --metric-reverted_40-50_color: rgba(125, 199, 172, .2);
  --metric-reverted_50-60_color: rgba(246, 192, 189, .2);
  --metric-reverted_60-70_color: rgba(246, 192, 189, .4);
  --metric-reverted_70-80_color: rgba(246, 192, 189, .6);
  --metric-reverted_80-90_color: rgba(246, 192, 189, .8);
  --metric-reverted_90-100_color: rgba(246, 192, 189, 1);

  --metric_0-10_color: rgba(246, 192, 189, 1);
  --metric_10-20_color: rgba(246, 192, 189, .8);
  --metric_20-30_color: rgba(246, 192, 189, .6);
  --metric_30-40_color: rgba(246, 192, 189, .4);
  --metric_40-50_color: rgba(246, 192, 189, .2);
  --metric_50-60_color: rgba(125, 199, 172, .2);
  --metric_60-70_color:  rgba(125, 199, 172, .4);
  --metric_70-80_color: rgba(125, 199, 172, .6);
  --metric_80-90_color: rgba(125, 199, 172, .8);
  --metric_90-100_color: rgba(125, 199, 172, 1);

  //--metric_0-20_color: #F6C0BD;
  //--metric_20-40_color: #FBE0DE;
  //--metric_40-60_color: #E3E3E4;
  //--metric_60-80_color: #7DC7AC;
  //--metric_80-100_color: #A8DAC8;
}
