@import "@root/theme/colors.scss";

.root {
  display: flex;
  flex-direction: column;
  color: $grayTextColor;
  text-align: start;
}

.topIndicatorContainer,
.bottomIndicatorContainer {
  position: relative;
  height: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-family: Poppins;
  transition: margin 0.7s;
}

.topIndicatorContainer {
  padding: 5px 0;
}

.bottomIndicatorContainer {
  padding: 16px 0;
}

.tooltip {
  background: linear-gradient(
    0.25turn,
    transparent 0%,
    $lightTextColor 20%,
    $lightTextColor 80%,
    transparent 100%
  );
  padding: 0px 0.3em;
  z-index: 10;
  transition: opacity 0.5s;
}

.limit {
  color: $grayTextColor;
  position: absolute;
  right: 0;
  z-index: 0;
  transition: opacity 0.5s;
}
