.root {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 200px;
  gap: 20px;
}

.linkList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 3px;
}

.control {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 8px;
  height: fit-content;
  margin: auto 0;
}

.clickable {
  cursor: pointer;
}
