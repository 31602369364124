@import '@root/theme/utils';

.editAssetGroupForm {
    position: relative;
}
.loaderWrapper {
    position: absolute;
    border-radius: 10px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 60px;
    background: $utilBg;
    opacity: .6;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 100px;
}
