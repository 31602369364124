@import "@root/theme/colors.scss"
@import "@root/theme/size.scss"

.root
  width: 8px
  min-height: 48px
  position: relative

.scale
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 48px
  display: flex
  flex-direction: column
  border-radius: 6px
  overflow: hidden
  background: grey
  border: 1px solid $black60Color
  box-sizing: border-box

  .scaleItem
    width: 100%
    flex: 1 0 auto

.knob
  position: absolute
  right: -4px
  height: 5px
  width: 15px
  border-radius: 3px
  border: 1px solid $black100Color
  background: $black05Color
