.root {
  display: flex;
}

.left {
  display: flex;
  column-gap: 45px;
  flex: 1 1;
  padding: 0 20px;
}

.right {
  display: flex;
  column-gap: 10px;
}

.datePicker {
  width: 280px;
}
