@import "@root/theme/size.scss";
@import "./ButtonColor.module.scss";
@import "./ButtonSize.module.scss";

// sizes
$border: $p02;
$paddingBlock: var(--btn-padding-block);
$paddingInline: var(--btn-padding-inline);

// colors
$mainColor: var(--btn-main-color);
$secondColor: var(--btn-second-color);
$contrastColor: var(--btn-contrast-color);
$focusBorderColor: var(--btn-focus-border-color);
$focusMainColor: var(--btn-focus-main-color);
$activeMainColor: var(--btn-active-main-color);
$hoverMainColor: var(--btn-hover-main-color);
$disabledMainColor: var(--btn-disabled-main-color);
$disabledContrastColor: var(--btn-disabled-contrast-color);

.root {
  display: flex;
  border-radius: $p08;
  box-sizing: border-box;
  font-size: $p12;
  line-height: $p18;
  font-weight: 600;
  font-family: Poppins;
  border-width: $border;
  border-style: solid;
  border-color: transparent;
  cursor: pointer;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  white-space: nowrap;
  box-shadow: none;
  outline: none;
  user-select: none;
  height: fit-content;
  
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &.hidden {
    visibility: hidden;
  }
}

.contained {
  padding-block: $paddingBlock;
  padding-inline: $paddingInline;

  color: $contrastColor;
  background-color: $mainColor;
  border-color: $mainColor;
  &:focus-visible {
    background-color: $focusMainColor;
    border-color: $focusBorderColor;
  }
  &:hover {
    background-color: $hoverMainColor;
    border-color: $hoverMainColor;
  }
  &:active, &.active {
    background-color: $activeMainColor;
    border-color: $activeMainColor;
  }
  &:disabled, &.disabled {
    background-color: $disabledMainColor;
    border-color: $disabledContrastColor;
    color: $disabledContrastColor;
  }
}

.outlined {
  padding-block: $paddingBlock;
  padding-inline: $paddingInline;

  color: $secondColor;
  background-color: $white100Color;
  border-color: $secondColor;
  &:focus-visible {
    color: $focusMainColor;
    border-color: $focusBorderColor;
  }
  &:hover {
    color: $hoverMainColor;
    border-color: $hoverMainColor;
  }
  &:active, &.active {
    color: $activeMainColor;
    border-color: $activeMainColor;
  }
  &:disabled, &.disabled {
    background-color: $contrastColor;
    border-color: $disabledContrastColor;
    color: $disabledContrastColor;
  }
}

.ghost {
  padding-block: $paddingBlock;
  padding-inline: $paddingBlock;

  color: $secondColor;
  background-color: transparent;
  &:focus-visible {
    color: $focusMainColor;
    border-color: $focusBorderColor;
  }
  &:hover {
    color: $hoverMainColor;
  }
  &:active, &.active {
    color: $activeMainColor;
  }
  &:disabled, &.disabled {
    color: $disabledContrastColor;
  }
}

.root {
  &:disabled, &.disabled {
    pointer-events: none;
  }
}
