.root {
  padding: 25px 37px 20px 37px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(26, 115, 169, 0.1);
  border-radius: 10px;
  text-align: center;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  margin: 71px 0 14px 0;
  text-align: left;
}

.section {
  display: flex;
}

.leftSection {
  margin-right: 30px;
}

.rightSection {
  margin-left: 30px;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.noData {
  padding: 31px 0;
}
