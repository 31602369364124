@import "@root/theme/colors.scss"
@import "@root/theme/size.scss"

.root
  display: flex
  flex-direction: column
  gap: $p08
  background: $white100Color
  padding: 7px
  border: 1px solid transparent
  cursor: pointer
  overflow: hidden

.root.clickable:hover
  background: $white100Color
  border-color: $primary40Color

.root.clickable:active,
.root.clickable.active
  border-color: $primary100Color

.root.disabled
  pointer-events: none
  cursor: not-allowed
  opacity: 0.5

.header
  display: flex
  justify-content: space-between
  align-items: center
  gap: $p08

.metricHeader
  min-height: 26px

.metricName
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  max-width: 140px

.metricsContainer
  margin-top: $p08

.root.clickable:hover .link
  text-decoration: underline

.metricWrap
  position: relative
  &:hover
    .metricPopupHelper
      display: block

.metricPopupHelper
  display: none
  position: absolute
  top: 0
  right: 0
  z-index: 20
  background: $black80Color
  border-radius: 4px
  padding: 5px 10px


// preview image
.previewVideo
  position: relative
  .previewVideoIcon
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: rgba(0,0,0,0.3)
    display: flex
    align-items: center
    justify-content: center

.previewContainer
  width: 100%
  height: 200px
  border-radius: $p08
  overflow: hidden
  background-color: $primary10Color
  background-size: 100% 100%
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start

  .image
    width: 100%
    height: 100%

// metrics

.metricChip
  border-radius: $p08
  padding: $p04 $p08

// *******************

.groupHeader,
.groupSubHeader
  position: relative
  &:hover
    .titleTooltip
      visibility: visible

  .titleTooltip
    width: 100%
    position: absolute
    visibility: hidden
    padding: 5px 7px
    border-radius: 5px
    top: 5px
    background-color: $black03Color
    opacity: 1
    z-index: 5
    box-shadow: 1px 1px 10px -5px $black70Color

  .titleOverflow
    padding: 5px 10px

.groupSubHeader
  .titleOverflow
    padding: 0

