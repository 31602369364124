@import "@root/theme/colors.scss"
@import "@root/theme/size.scss"

.root
  display: flex
  flex-direction: column
  overflow: hidden
  padding: 0
  gap: 0
  border: 1px solid $primary20Color

.header
  padding: 12px 16px 10px
  border-bottom: 1px solid $primary60Color

.bodyContainer
  flex: 1
  margin-bottom: 12px

.body
  display: flex
  flex-direction: column
  padding-block: 12px 4px
  padding-inline: 16px
  gap: 16px

.grid
  display: grid
  grid-template-columns: repeat(2, 1fr)
  gap: $p08

.titleTooltip
  position: absolute
  visibility: hidden
  padding: 5px 7px
  border-radius: 5px
  right: 0
  top: 20px
  background-color: $black03Color
  opacity: 0
  z-index: 5
  box-shadow: 1px 1px 10px -5px $black70Color
  transition: opacity 0.3s ease, visibility 0.3s ease

.nameTitle
  position: relative
  &:hover .titleTooltip
    visibility: visible
    opacity: 1
