@import '@root/theme/utils.scss';

.root {
  padding: 0;
  &>*:not(:last-child) {
    border-bottom: 1px solid $black20Color
  }
}

.header {
  padding: $p10 $p24;
}

.body {
  padding: $p16 $p24;
}

.image {
  height: 80px;
  width: 100%;
}

.images {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: $p08;
}

.footer {
  padding: $p10 $p24;
}
