@import "@root/theme/utils.scss"

.root
  display: flex
  align-items: flex-start
  gap: $p32

  &>*:nth-child(1)
    display: flex
    flex-direction: column
    min-width: 300px
    gap: $p04

  &>*:nth-child(2)
    flex: 1 1 100%
