@import "@root/theme/utils.scss";

.root {
  width: 100%;
  position: relative;
  bottom: 0;
  z-index: 2;
}

.footer {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  height: 72px;
  bottom: 0;
  background: $white100Color;
  box-shadow: $primaryShadowL2;
  padding: 15px 60px 15px 134px;
}
