@import "@root/theme/utils.scss";

.root {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: min(90vw, 900px);
  height: 100%;
  padding: $p24 $p32;
  overflow: auto;
  gap: $p12;
}
