
@keyframes loading
  from
    left: -30%
  to
    left: 130%

.root
  display: flex
  flex-direction: column
  width: 100%
  max-width: 100%
  gap: 5px

.line
  height: 1em
  background: gray
  opacity: .3
  position: relative
  overflow: hidden
  margin: 0
  border-radius: 2px

.root.withAnimation .line:before
  content: ''
  display: block
  position: absolute
  top: 0
  left: 0
  width: 40%
  height: 100%
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0), #313131 50%, rgba(0, 0, 0, 0))
  opacity: 0.5
  will-change: left
  filter: blur(5px)
  border-radius: 50%
  animation: loading 1.5s linear infinite

.root.align-start
  align-items: start

.root.align-end
  align-items: end

.rounded .line
  border-radius: 6px
