.root {
  width: 100%;
  height: max-content;
  padding: 40px 0;
}

.header {
}

.body {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 30px;
}

.footer {
  display: flex;
  row-gap: 20px;
  column-gap: 20px;
  justify-content: flex-end;
  position: relative;
  z-index: 0;
}

.justifiedStart {
  display: flex;
  justify-content: flex-start;
}

.row {
  display: flex;
  padding: 15px 20px;
  gap: 60px;
}

.field {
  width: 250px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.shortRow {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  z-index: 1;
}

.controlIcon {
  font-size: 22px;
  cursor: pointer;
}
