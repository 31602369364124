@import "@root/theme/utils.scss";

.root {
  position: relative;
  max-width: fit-content;
  width: fit-content;
  min-width: inherit;
  min-height: inherit;
  background: $pageBg;
}

.container {
  display: flex;
  width: fit-content;
  min-width: 100%;
  height: 100%;
  flex-flow: column;
  flex-grow: 1;
  align-content: stretch;
  z-index: 1;
}

.header {
  z-index: 3;
}

.search {
  display: flex;
  align-items: center;
}

.mainHeader {
  justify-content: space-between;
  padding-inline: 138px 60px;
  z-index: 1000;
  &.disabled {
    pointer-events: none;
  }
}

.navContainer {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0 60px 0 138px;
  width: 100%;
}

.profile {
  display: flex;
  justify-self: flex-end;
}

.sidebar {
  z-index: 100;
  top: 0;
  left: 0;
  position: fixed;
  min-height: 700px;
  height: 100%;
  user-select: none;
}

.body {
  display: flex;
  width: fit-content;
  min-width: 100%;
  min-height: 50vh;
  box-sizing: border-box;
  padding-left: 94px;
}

.menu {
  margin: auto;
}

.root.maxScreen {
  height: 100vh;

  .body {
    flex: 1 1 100%;
    overflow: auto;
  }
}