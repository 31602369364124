.root {
  margin-top: 74px;
}

.wrapper {
  display: flex;
  margin-top: 20px;
  column-gap: 66px;
  align-items: stretch;
  min-height: 360px;
  flex-wrap: wrap;
}

.column {
  flex: 1 1;
}
