.root {
  padding: 25px;
}

.container {
  width: 100%;
  max-width: 540px;

  & > div:not(:last-child) {
    margin-bottom: 25px;
  }
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 540px;
}

.filesWrapper {
  display: flex;
  margin-top: 13px;
  & > button {
    margin-right: 14px;
    padding: 0;
  }
}

.documentsContainer > div:not(:last-child) {
  margin-bottom: 5px;
}

.dropzone {
  background: #f6f8ff;
}

.fileProgress {
  & > div:first-child {
    background: #f6f8ff !important;
  }
}
