.header {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 45px;
}

.menuContainer {
  flex: 1 0 315px;
}

.menu {
  padding: 24px 14px 14px
}

.menuLabel {
  padding-inline: 10px;
}


.bodyContainer {
  flex: 1 1 100%;
}

.label {
  display: flex;
  align-items: center;
  gap: 10px;
  user-select: none;
  cursor: pointer;
}