@import "@root/theme/variables.scss";

@mixin respond-to($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobileWidth) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: calc(#{$mobileWidth} + 1)) and (max-width: calc(#{$desktopWidth} - 1)) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $desktopWidth) {
      @content;
    }
  }
}

@mixin thinScrollbar($selector: '*') {
  #{$selector}::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  #{$selector}::-webkit-scrollbar-track {
    width: 6px;
    background-color: #e2e6f2;
    border-radius: 10px;
    box-shadow: none;
  }

  #{$selector}::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    background: #bec4d9;
  }
}

@mixin ghostScrollbar($selector: '*') {
  #{$selector}::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  #{$selector}::-webkit-scrollbar-track {
    width: 6px;
    background-color: transparent;
    border-radius: 10px;
    box-shadow: none;
  }

  #{$selector}::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    background: #bec4d9;
  }
}

@mixin secondaryScrollbar($selector: '*') {
  #{$selector}::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.8rem;
  }

  #{$selector}::-webkit-scrollbar-track {
    background-color: #e2e6f2;
    border-radius: 10px;
    box-shadow: none;
  }

  #{$selector}::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    background: #bec4d9;
  }
}

@mixin hiddenScrollbar() {
  &::-webkit-scrollbar {
    display: none;
  }
}
