@import '@root/theme/utils.scss';

$primaryShadowLevels: 1 , 2 , 3;

.root {
  box-shadow: $secondaryShadowL1;
  background: $white100Color;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  padding: var(--box-padding);
  width: var(--box-width);
  height: var(--box-height);
}

.shadow-L0 {
  box-shadow: none;
}

@each $lvl in $primaryShadowLevels {
  .shadow-L#{$lvl} {
    box-shadow: var(#{'--primary-shadow-l#{$lvl}'});
  }
}
