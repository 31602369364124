@import "@root/theme/utils.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: $p08
}

.body {
  display: flex;
  gap: $p08
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  gap: $p08
}