@import "@root/theme/utils.scss";

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  user-select: none;
  flex-wrap: wrap;
}

.item {
  display: flex;
  position: relative;
  align-items: center;
  padding: 0;
  gap: $p10;
  font-size: $p12;
  font-weight: 500;
  letter-spacing: 0.01em;
  background: transparent;
  color: $black60Color;
  border: none;
  cursor: pointer;

  i {
    font-size: 1.667em;
  }
}

.dir-vertical {
  flex-direction: column;
  align-items: stretch;
  .item {
    justify-content: flex-start;
    padding-inline: $p26;
    padding-block: $p10;
    border-right: $p02 solid transparent;
  }
}

.dir-horizontal {
  flex-direction: row;
  .item {
    white-space: nowrap;
    justify-content: center;
    padding-inline: $p20;
    padding-block: $p08;
    border-bottom: $p02 solid transparent;
  }
}

.fullWidth {
  width: 100%;
  flex-wrap: nowrap;
  .item {
    flex: 1 1 100%;
  }
}

.underlined {
  .item {
    border-color: $black20Color;
  }
}

.item {
  &.iconSVG {
    flex-direction: column;
  }
  &:focus-visible {
    outline: 1px solid $success100Color;
    color: $black100Color;
  }

  &:hover {
    color: $black100Color;
  }

  &.active, &:active {
    font-weight: 600;
    color: $primary100Color;
    border-color: $primary100Color;
  }

  &.disabled, &:disabled {
    color: $black30Color;
    pointer-events: none;
    cursor: not-allowed;
  }
}
