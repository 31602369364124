.root {
  text-align: center;
}

.buttonsWrapper {
  margin: auto;
  margin-top: 46px;
  width: min-content;

  & button {
    width: 280px;
  }

  & button:first-child {
    margin-bottom: 10px;
  }
}