@import "@root/theme/colors.scss";

.root {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  border: solid #bdc1c6;
  border-width: 4px 4px 0;
  padding: 0 4px;
  background: white;
  display: flex;
  flex-direction: column;
  width: 450px;
  margin: auto;
}

.content {
  margin: 0 10px;
  min-height: 200px;
  background: $pageBg;
  padding: 16px 8px;
}

.camera {
  align-self: center;
  border: 1px solid #999;
  border-radius: 50%;
  display: inline-flex;
  height: 9px;
  margin-bottom: 8px;
  margin-top: 12px;
  width: 9px;
}
