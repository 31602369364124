.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  height: 100%;
  width: 705px;
}

.containerPading {
  box-sizing: border-box;
  padding: 2rem 2rem 0 2rem;
}
.footer {
  box-shadow: 0px -4px 12px rgba(233, 236, 246, 0.8);
  padding: 1.5rem 2rem;
}

.input {
  margin-top: 1rem;
}
