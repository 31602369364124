@import "@root/theme/colors.scss";

.wrapper {
  border: 1px solid $utilBorder;
  border-radius: 10px;
  padding: 7px 15px;

  width: 100%;
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  min-height: 21px;
  color: $darkTextColor;
  background: $white100Color;
  box-sizing: border-box;

  &:focus-within {
    border-color: $darkTextColor;
  }

  &:focus {
    outline: none;
  }

  resize: none;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    width: 10px;
    background-color: transparent;
    border-radius: 10px;
    box-shadow: none;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid transparent;
    background: transparent;
    background-clip: content-box;
  }
}

.root {
  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2px;
    font-weight: 600;
    font-size: 12px;
    div:first-child {
      text-transform: uppercase;
      color: $grayTextColor;
      font-weight: normal;
    }
  }
}

.resizable {
  resize: vertical;
}

.valid {
  border: 1px solid $lightGreenColor;
}

.error {
  border: 1px solid $redTextColor;
}

.withIcon {
  padding-left: 46px;
}
