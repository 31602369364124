.root {
}

.container {
  display: flex;
  margin-top: 22px;

  & > div:not(:last-child) {
    margin-right: 85px;
  }
}

.list {
}

.column {
  display: grid;
  grid-auto-rows: max-content;
  gap: 15px;
}
