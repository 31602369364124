$pageBg: #f6f8ff;

$bgColor: #eaeaea;
$secondaryDarkColor: #eaeaea;
$secondaryDarkShadow: #eaeaeaad;
$secondaryLightColor: #d8d8d8;
$primaryLightColor: #5cbd9f;
$primaryLightShadow: #5cbc9e4d;
$primaryDarkColor: #17899e;
$primaryDarkColorLighter: #24a5bc;
$primaryDarkColorDarken: #0c6474;
$primaryDarkShadow: #17899e4d;
$grayTextColor: #868686;
$darkTextColor: #000;
$lightTextColor: #fff;
$redTextColor: #f97373;
$redBackgroundColor: #e9635a;
$lightGreenColor: #39bd56;
$darkBlueColor: #1a8c9e;
$yellowBackgroundColor: #e3ac20;
$mediumDarkBlueColor: #325073;
$lightGreen: #18b560;
$lightGray: #c9c9c9;
$darkGray: #555555;

$utilBg: #fdfdfd;
$utilBorder: #cbcbcb;

$assetImageBorder: #9C9EA2;
$assetImagesBg: #E3E3E4;

$conditionMarkColor: #004450;
$campaignMarkColor: #17899e;
$adGroupMarkColor: #92cbd6;
$keywordsMarkColor: #93d692;
$adMarkColor: #5cbd9f;
$assetsProgressBgColor: #E8F4F6;

$unitIncludedBgColor: #178038;
$unitExcludedBgColor: #757575;
$subdivisionBgColor: #9aa0a6;

// new design system colors

$white100Color: var(--white-100-color);

$black100Color: var(--black-100-color);
$black90Color: var(--black-90-color);
$black80Color: var(--black-80-color);
$black70Color: var(--black-70-color);
$black60Color: var(--black-60-color);
$black40Color: var(--black-40-color);
$black30Color: var(--black-30-color);
$black20Color: var(--black-20-color);
$black10Color: var(--black-10-color);
$black05Color: var(--black-05-color);
$black03Color: var(--black-03-color);

$primary140Color: var(--primary-140-color);
$primary120Color: var(--primary-120-color);
$primary100Color: var(--primary-100-color);
$primary80Color: var(--primary-80-color);
$primary60Color: var(--primary-60-color);
$primary40Color: var(--primary-40-color);
$primary20Color: var(--primary-20-color);
$primary10Color: var(--primary-10-color);
$primary05Color: var(--primary-05-color);

$secondary140Color: var(--secondary-140-color);
$secondary120Color: var(--secondary-120-color);
$secondary100Color: var(--secondary-100-color);
$secondary80Color: var(--secondary-80-color);
$secondary60Color: var(--secondary-60-color);
$secondary40Color: var(--secondary-40-color);
$secondary20Color: var(--secondary-20-color);
$secondary10Color: var(--secondary-10-color);

$success120Color: var(--success-120-color);
$success100Color: var(--success-100-color);
$success80Color: var(--success-80-color);

$info120Color: var(--info-120-color);
$info100Color: var(--info-100-color);
$info80Color: var(--info-80-color);
$info60Color: var(--info-60-color);
$info40Color: var(--info-40-color);
$info20Color: var(--info-20-color);

$error120Color: var(--error-120-color);
$error100Color: var(--error-100-color);
$error80Color: var(--error-80-color);
$error40Color: var(--error-20-color);
$error20Color: var(--error-20-color);


