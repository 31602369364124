.root {
  width: 100%;
  padding: 40px 0;
  height: max-content;
}

.header {
  margin-bottom: 47px;
}

.body {
  max-width: 100%;

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: linear-gradient(180deg, #5cbd9f 0%, #17899e 100%);
  }

  &::-webkit-scrollbar-track {
    background: none;
    box-shadow: none;
  }
}
