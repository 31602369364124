@import "@root/theme/size.scss";

.root {
  &.hidden {
    visibility: hidden;
  }
  &.fullWidth {
    width: 100%;
  }

  .endIcon,
  .startIcon {
    font-weight: 500;
    font-size: $p18;
  }
  .before,
  .after {
    display: flex;
    align-items: center;

  }

  i {
    font-size: 1.4em;
  }

  .after,
  .endIcon {
    margin-left: 0.5em;
  }
  .before,
  .startIcon {
    margin-right: 0.5em;
  }
}
