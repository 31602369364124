.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 30px;
}

.control {
  display: flex;
}

.controlSection {
  display: flex;
  & button {
    font-size: 20px;
    padding: 10px !important;
    &:hover {
      color: rgb(53, 53, 53) !important;
    }
  }
  &:not(:last-child) {
    &:after {
      content: "";
      width: 1px;
      height: calc(100% - 10px);
      margin: 5px 10px;
      background: rgb(209, 209, 209);
    }
  }
  .arrow {
    font-size: 16px;
  }
}
