@import '@root/theme/utils';

.audienceSignalWrap {
    background-color: $white100Color;
    padding: 24px;
    border-radius: 10px;
    box-shadow: $primaryShadowL1;
    margin-bottom: 25px;
    cursor: pointer;
}
.textIcon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.audienceSignalTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
}
.audienceSignalItem {
    position: relative;
    outline: 1px solid $bgColor;
    border: 2px solid transparent;
    border-radius: 4px;
    padding: 16px;
    margin: 0 2px 16px;
    width: 100%;
    box-sizing: border-box;
    &.selected {
        border: 2px solid $primaryDarkColor;
    }
}
.audienceSignalItemInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.audienceSignalSwitch {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.audienceSignalInfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: $assetsProgressBgColor;
    border-radius: 8px;
    padding: 16px;
}
.audienceSignalInfoIcon {
    margin-right: 8px;
}
.sidebar {
    position: relative;
    min-height: 100%;
    padding: 34px;
    box-sizing: border-box;
    min-width: 710px;
}
.audiencesList {
    overflow-y: auto;
    width: 100%;
    padding: 0 0 100px;
    .audienceSignalItem {
        width: 95%;
        &:hover {
            border: 2px solid $primaryDarkColor;
        }
    }
}
.searchField {
    min-width: 340px;
}
.audienceSignalItemIcon {
    position: absolute;
    right: -16px;
    top: calc(50% - 16px);
}
.sidebarFooter {
    background-color: $white100Color;
    position: fixed;
    bottom: 0;
    height: 84px;
    padding: 24px;
    right: 0;
    left: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: $secondaryShadowL3;
    z-index: 2;
}
.sidebarButton {
    margin-right: 16px;
}
