@import "@root/theme/utils.scss";

.root {
  width: 100%;
  box-sizing: border-box;
  padding-left: $p16;
  flex: 1 1 100%;
  min-height: 100%;
}

.header, .row {
  & > *:nth-child(2) {
    flex: 0 1 90px;
  }
  & > *:nth-child(4) {
    flex: 0 1 50px;
  }
}

.header .counter {
  display: flex;
  align-items: center;
  border-right: $p01 solid $black60Color;
  padding-inline: $p08 $p12;
}

.body {
  flex: 1 1 100%
}

.root .withControl {
  gap: $p05
}

.disabled {
  opacity: .6;
  text-decoration: line-through;
}