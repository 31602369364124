.root {
  padding: 25px 0 31px 27px;
}

.container {
  display: flex;
  margin-top: 24px;
  column-gap: 60px;
  & > * {
    max-width: 250px;
    min-width: 150px;
  }
}

.wrapper {
  margin-bottom: 35px;
}

.formContainer {
  width: 540px;
  margin-top: 24px;

  & > div:not(:last-child) {
    margin-bottom: 12px;
  }
}

.fieldsWrapper {
  display: flex;

  & > div {
    width: 100%;
  }

  & > div:first-child {
    margin-right: 24px;
  }
}

.field {
  width: calc(50% - 12px);
}

.dropDown {
}
