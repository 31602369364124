body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Poppins, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

#root {
  min-height: 100%;
  height: 100%;
}

:root {
  /*
   * @deprecated
   */ 
  --global-total-height: 100vh;
  --global-total-width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
