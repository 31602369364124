@import "@root/theme/colors.scss";

.root {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0;
}

.label {
  font-family: Poppins, sans-serif;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.point {
  width: 44px;
  height: 16px;
  border-radius: 10px;
  margin-right: 10px;
}
