@import "@root/theme/mixins.scss";
@import "@root/theme/colors.scss";

.root {
  height: var(--global-total-height, 100vh);
}

.title {
  letter-spacing: 2px;
  @include respond-to(mobile) {
    padding: 28px 0 0 28px;
  }
}

.wrapper {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
}

.root.color-default {
  background: #f6f8ff;
  @include respond-to(mobile) {
    background: #ffffff;
  }
}

.root.color-primary {
  background: linear-gradient(
    180deg,
    $primaryLightColor 0%,
    $primaryDarkColor 100%
  );
  color: $lightTextColor;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  flex: 1 1 100%;
  margin-inline: auto;
  height: 100%;
  padding-inline: 40px;
  align-items: center;
}

.header {
  padding: 30px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: default;
  user-select: none;
}

.before,
.after {
  flex: 1 1 100%;
  display: flex;
  align-items: center;
}

.before {
  justify-content: flex-start;
}

.after {
  justify-content: flex-end;
}
