@import "@root/theme/utils.scss";

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 0;
  height: max-content;
  max-width: 1400px;
  margin-inline: auto;
  overflow: auto;
  flex: 1 1 100%
}

.header {
  margin-bottom: 24px;
}

.body {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  max-width: 100%;
  overflow: auto;

  @include secondaryScrollbar('&');
}
