@import "@root/theme/utils.scss";

.root {
  width: fit-content;
  border-radius: $p08;
  padding: $p08 $p10;
  margin-block: -$p08;
  &:hover {
    background: $secondary40Color;
  }
}

.column {
  display: flex;
  flex: 1 1;
}

.doneBadge {
  font-size: 42px;
  color: $success100Color
}
