@import "@root/theme/utils.scss";

.root {
  display: flex;
  z-index: 0;
}

.stepIcon {
  position: relative;
  font-size: $p19;
  color: $black20Color;
  box-sizing: border-box;
  z-index: 1;
  & i {
    font-weight: bold;
    font-size: 0.9em;
    height: 100%;
  }
}

.section {
  display: flex;
  position: relative;
  box-sizing: border-box;
}

.line {
  background: $black20Color;
  position: absolute;
}

.line.active {
  background: $primary100Color;
}

.label {
  color: $black100Color;
}

.wrapper {
  display: flex;
  border: none;
  background: none;
  padding: 0;
}

.wrapper.clickable {
  cursor: pointer;
}

.active, .completed, .skipped {
  .stepIcon {
    color: $primary100Color;
  }
}

.active {
  .label {
    font-weight: 500;
    color: $primary100Color
  }
}


.wrapper:disabled {
  cursor: not-allowed;
}

.root.ori-vertical {
  flex-direction: column;

  .stepIcon {
    margin-inline: 0 16px;
    margin-block: 1px;
  }

  .line {
    width: 1px;
    top: -23px;
    left: 19px;
    height: 28px;
  }

  .section {
    justify-content: flex-start;
    min-height: 45px;
    padding-top: 3px;
    padding-inline: 10px;
    width: 240px;
  }
  .wrapper {
    text-align: start;
    flex-direction: row;
    align-items: flex-start;
  }
}

.root.ori-horizontal {
  flex-direction: row;

  .stepIcon {
    margin-inline: 12px;
    margin-block: 0 8px;
  }

  .line {
    height: 1px;
    top: 9.5px;
    right: calc(50% + 9.5px + 12px);
    width: calc(100% - 42px);
  }

  .section {
    justify-content: center;
    padding-block: 0;
    padding-inline: 20px;
    width: 190px;
  }
  .wrapper {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
}