.checkboxContainer {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 1rem 2rem 0 2rem;
  box-sizing: border-box;
}

.nodeContainer {
  padding-top: 0.5rem;
}
