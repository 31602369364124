@import "@root/theme/colors.scss";
@import "@root/theme/size.scss";

.root {
  width: 100%;
  height: max-content;
  position: relative;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 0.25fr));
  gap: $p12;
  justify-content: start;
}

.table {
  height: calc(100vh - 350px);
  max-height: 600px;
  width: 100%;
  overflow: hidden;
  border-radius: $p08;
  background: $white100Color;
  & *::-webkit-scrollbar-track {
    background: $white100Color;
  }
}

.colorMetricsPanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  gap: 10px 10px;
  margin-top: 10px;
}
.colorMetricsSelect {
  position: absolute;
  left: 10px;
  bottom: -40px;
  z-index: 10;
}
.filterChip {
  //margin-right: 12px;
  max-width: none;
}
.gridGroups {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 0.25fr));
  gap: $p12;
  justify-content: start;
}
.gridGroups .fullWidth {
  grid-column: 1 / -1
}
.goalsGrid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, 1fr);
  gap: $p12;
}
.wrongReportPage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 50px 0 0;
}
@media (min-width: 1280px) {
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(255px, 0.25fr));
  }
  .gridGroups {
    grid-template-columns: repeat(auto-fit, minmax(255px, 0.25fr));
  }
}
