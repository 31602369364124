@import "@root/theme/colors.scss"
@import "@root/theme/size.scss"

.root
  width: 1000px

.stepper
  padding-block: $p10 $p16
  justify-content: center
  width: 100%

.body
  padding: $p24
