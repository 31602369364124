.root {
  color: black;
  font-style: normal;
  font-family: Poppins;
  white-space: pre-line;
  overflow-wrap: break-word;
  font-size: 14px;
  font-weight: 500;
  p {
    margin: 0;
    margin-bottom: 0.6em;
  }
  ul {
    margin-top: 0.15em;
    margin-bottom: 0.45em;
  }
  li {
    margin-block: 0.45em;
  }
  h1 {
    font-size: 2em;
    line-height: 140%;
    margin-top: 0;
    margin-bottom: 0.5em;
  }
  h2 {
    font-size: 1.5em;
    line-height: 140%;
    margin-top: 1.5em;
    margin-bottom: 0.25em;
  }
  h3 {
    font-size: 1.167em;
    line-height: 1.75em;
    margin-top: 1em;
    margin-bottom: 0.2em;
  }
  h4 {
    margin-top: 1em;
    margin-bottom: 0.3em;
  }
  .caption {
    font-style: italic;
    margin-block: 1.2em;
  }
  .subtitle {
    font-style: italic;
    text-transform: uppercase;
    color: #868686;
  }
}
