@import "@root/theme/utils.scss";

.root {
  position: relative;
  width: 300px;
  height: min-content;
  max-height: 100%;
  flex: 1 1;
  z-index: 1;
  pointer-events: none;
  --sticky-offset: 0;
}
