.root {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.col {
  flex: 1 1;
  overflow: hidden;
}

.notEditable {
  height: 100%;
  min-height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
}
