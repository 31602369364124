@import "@root/theme/utils.scss";

.root {
  display: flex;
  flex-direction: column;
  min-height: 300px;
}

.placeholder{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 20px;
  width: 100%;
}