@import "@root/theme/utils.scss";

$labelWidth: var(--label-width, 350px);
$defaultTipWidth: 10px;
$borderRadius: 10px;

.root {
  --left-padding: 14px;
  --label-shift: 28px;
  --label-cut: var(--label-shift);
  flex: 1 1;
}

.header {
  display: flex;
  padding-right: $defaultTipWidth;
  padding-left: var(--label-cut);
  padding-bottom: 5px;
  min-width: 100%;
  box-sizing: border-box;
  cursor: default;
}

.body {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

// row

.rowRoot {
  flex-direction: column;
  display: flex;
  row-gap: 10px;
  flex: 1 1;
  --label-cut: var(--label-shift);
}

.rowContainer {
  display: flex;
  flex: 1 1;
}

.shifter {
  --label-shift: calc(var(--label-cut) + var(--left-padding));
}

.rowHeader {
  display: flex;
  flex: 1 1;
  border-radius: $borderRadius;
  background-color: $white100Color;
  min-height: 36px;
  padding-right: $defaultTipWidth;
  :is(.column, .label) {
    overflow: auto;
    line-height: 170%;
  }
  & > *:last-child {
    border-radius: 0 $borderRadius $borderRadius 0;
  }
  & > *:first-child {
    border-radius: $borderRadius 0 0 $borderRadius;
  }

  .status {
    padding-inline: $p08;
  }
}

.rowHeader.active {
  background-color: $primary20Color;
}

.rowHeader.big {
  height: 59px;
}

.rowBody {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.indent {
  user-select: none;
  display: flex;
  align-items: center;
  font-size: 10px;
  color: black;
  width: var(--label-cut);
  justify-content: flex-end;
}

.label {
  display: flex;
  align-items: center;
  width: calc(#{$labelWidth} - var(--label-cut));
  min-width: calc(#{$labelWidth} - var(--label-cut));
  max-width: calc(#{$labelWidth} - var(--label-cut));
  flex: none;
}

.labelText {
  padding-left: $defaultTipWidth;
}

.withTip .label > * {
  padding-left: 45px;
}

:is(.column) {
  display: flex;
  align-items: center;
  flex: 1 1;
  gap: 7px;
  padding: 5px 7px;
  overflow-wrap: anywhere;
}

.header :is(.column) {
  align-self: center;
}

.column.sortable {
  cursor: pointer;
}

.rowHeader:hover {
  box-shadow: 0px 0px 5px $primaryLightShadow;
}

.nose {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  color: $lightTextColor;
  background-color: var(--group-color, #{$campaignMarkColor});
  width: 33px;
  height: 100%;
  border-radius: $borderRadius 0 0 $borderRadius;
  margin-right: 5px;
  font-size: 14px;
}

.loader {
  margin-left: 70px;
}

.rowHeader.clickable {
  cursor: pointer;
}