@import "@root/theme/utils.scss";

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 700px;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding: 0 $p32;
  overflow: hidden;
}

.section {
  width: 100%;
  padding-bottom: $p24;
}

.section:not(:last-child) {
  border-bottom: 1px solid $black20Color;
}

.scrollContainer {
  flex: 1 1;
  margin-inline: -$p18;
}

.bodyContainer {
  max-height: 100%;
  padding-inline: $p18;
  overflow-y: overlay;
}

.sectionBody {
  padding-block: $p10;
}

.row {
  display: grid;
  grid-template-columns: 160px 1fr;
}

.header {
  padding: $p32;
}

.footer {
  padding: $p24 $p32;
  box-shadow: 0px -4px 12px rgba(233, 236, 246, 0.8);
}
