@import "@root/theme/utils.scss"

.root
  display: flex
  flex-direction: column
  width: 100%
  @include secondaryScrollbar()

.container
  min-height: 500px
  padding-top: 24px
