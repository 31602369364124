@import "@root/theme/colors.scss";

.root {
  display: flex;
  text-align: center;
  flex-direction: column;
  height: 100%;
}

.chartContainer {
  position: relative;
  flex: 1 1;
}

.chart {
  position: absolute;
  width: 100%;
  height: 100%;
}

.legendsContainer {
  margin-top: 7px;
  padding: 0 50px;
}
