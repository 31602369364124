.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftSection, .rightSection {
  display: flex;
  gap: 16px
}

.leftSection {
  gap: 34px
}

.rightSection {
  z-index: 0;
}

.metric {
  display: flex;
}

.withIcon i {
  font-size: 1.5em;
  margin-right: .3em;
}