@import "@root/theme/colors.scss";
@import "@root/theme/size.scss";

.root {
  width: 100%;
  height: max-content;
  position: relative;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 0.25fr));
  gap: $p12;
  justify-content: start;
}
.gridGroups {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 0.25fr));
  gap: $p12;
  justify-content: start;
}
.table {
  height: calc(100vh - 415px);
  width: 100%;
  overflow: hidden;
  border-radius: $p08;
  background: $white100Color;
  & *::-webkit-scrollbar-track {
    background: $white100Color;
  }
}

.colorMetricsPanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  gap: 10px 10px;
  margin-top: 10px;
}
.colorMetricsSelect {
  position: absolute;
  left: 0;
  top: 40px;
  z-index: 2;
  border: 1px solid $primaryDarkColor;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 4px 15px 0 rgba(32, 32, 32, 0.1);
  background-color: $white100Color;
}
.colorMetricsSelectInput {
  margin-bottom: 10px;
}
.filterChip {
  max-width: none;
}
.gridGroups .fullWidth {
  grid-column: 1 / -1
}
.goalsGrid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  gap: $p12;
}
@media (min-width: 1280px) {
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(255px, 0.25fr));
    justify-content: start;
  }
  .gridGroups {
    grid-template-columns: repeat(auto-fit, minmax(255px, 0.25fr));
    justify-content: start;
  }
}
