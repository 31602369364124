@import "@root/theme/colors.scss";

.root {
  width: 100%;
  background-color: $bgColor;
  overflow: hidden;
  position: relative;
}

.overflowGradient {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

.bar {
  width: 100%;
  height: 100%;
  background-color: $primaryDarkColor;
  transition: width var(--bar-transition-speed, 0.7s) linear;
}

.root.tiny {
  height: 7px;
  border-radius: 0;
  & > .bar {
    background-color: #086172;
    border-radius: 0;
  }
}

.root.small {
  height: 15px;
  border-radius: 30px;
  & > .bar {
    border-radius: 30px;
  }
}

.root.medium {
  height: 21px;
  border-radius: 30px;
  & > .bar {
    border-radius: 30px;
  }
}

.root.big {
  height: 50px;
  border-radius: 10px;
  & > .bar {
    border-radius: 10px;
  }
}

.root.horizontal > .bar {
  background-image: linear-gradient(
    180deg,
    $primaryLightColor 0%,
    $primaryDarkColor 100%
  );
}

.root.vertical > .bar {
  background-image: linear-gradient(
    270deg,
    $primaryLightColor 0%,
    $primaryDarkColor 97.6%
  );
}
