.root {
  display: grid;
  width: calc(100% - 2px);
  margin: 7px 0px;
  align-items: center;
  grid-template-columns: 55px 1fr;
}

.row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
  width: 100%;
}

.withBorder {
  border: 1px solid #c9c9c9;
  border-radius: 10px;
  min-height: 35px;
}
