@import '@root/theme/utils.scss';

.root {
  display: flex;
  box-sizing: border-box;
  height: fit-content;
  width: fit-content;
  max-height: $p24;
  max-width: $p24;
  padding: 0;
  color: $primary100Color;
}

.mark {
  border: $p02 solid transparent;
  border-radius: $p02;
  font-size: $p20;
  cursor: pointer;
}

.input {
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  visibility: hidden;
  opacity: 0;
  
  &:focus-visible + .mark {
    color: $primary60Color;
    border-color: $success100Color;
  }

  &:hover + .mark {
    color: $primary120Color;
  }

  &:active + .mark {
    color: $primary140Color;
  }

  &:disabled + .mark {
    color: $black40Color;
    cursor: default;
  }
}
