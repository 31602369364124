.root {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list {
  display: flex;
  gap: 12px;
}

.image {
  height: 44px;
  width: 78px;
}

.control {
  display: flex;
  gap: 20px;
  font-size: 1.2em;
  color: gray
}

.disabled {
  color: lightgrey;
  cursor: default;
}