@import '@root/theme/mixins.scss'

.root
  display: grid
  gap: 14px
  align-items: flex-start
  grid-template-columns: 340px 260px 210px 320px auto auto
  grid-template-rows: 1fr
  padding: 16px 24px
  @include thinScrollbar('&')

.input
  display: flex
  align-items: center
  gap: 10px
  & > *
    white-space: nowrap
  & > *:last-child
    flex: 1 1

.control
  display: flex
  gap: 12px

.periodLabel
  text-align: center
  min-width: 12ch

.helperText
  text-align: center
  margin-top: 4px
  margin-left: 9ch
