@import "@root/theme/colors.scss";

.row {
  padding: 10px 0;
  min-height: 56px;
  display: flex;
}

.header {
  display: flex;
  user-select: none;
}

.header,
.body {
  position: relative;
  padding: 0 20px;
  &.withDetails {
    padding-right: 50px;
  }
}

.item {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.header .item {
  flex-direction: row;
  gap: 8px;
}

.item:first-child {
  flex: 2 1;
  font-size: 18px;
  font-weight: bold;
  text-align: start;
}

.detailsContainer {
  margin-top: 10px;
}
.detailsContainer .item:first-child {
  font-size: 0.9em;
  font-weight: 200;
}

.openButton {
  padding: 10px;
  position: absolute;
  font-size: 18px;
  cursor: pointer;
  right: 10px;
  top: 20px;
}

.openButton:hover {
  color: $grayTextColor;
}

.clickable {
  cursor: pointer;
}

.invisible {
  visibility: hidden;
}
