@import "@root/theme/colors.scss";

.root {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  height: 75px;
  padding-inline: 60px;
  box-shadow: 0px 4px 15px $primaryDarkShadow;
  background-color: $lightTextColor;
  justify-content: center;
  box-sizing: border-box;
}
