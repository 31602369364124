@import "@root/theme/colors.scss";

.row {
  display: flex;
  align-items: center;
  padding: 8px 0;
  gap: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  user-select: none;
  cursor: pointer;
  &:hover {
    color: $primaryLightColor;
  }
}
