@import "@root/theme/colors.scss";

.root {
  color: black;
  font-style: normal;
  font-family: Poppins;
  white-space: pre-line;
  display: inline;
  cursor: inherit;
}

.removed {
  background: #ff545433;
  text-decoration: line-through;
}

.added {
  background: #49aea066;
}
