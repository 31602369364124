.root {
  position: relative;
  overflow: visible;
  height: 100px !important;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div:first-child {
    margin-bottom: 3px;
  }
}

.clickable {
  cursor: pointer;
}

.content {
  width: 372px !important;
  display: none;
  position: absolute;
  top: calc(100% - 7px);
  left: -86px;
  z-index: 5;
  overflow: visible;

  &::after {
    content: "";
    position: absolute;
    height: 20px;
    width: 200px;
    background: white;
    top: -10px;
    left: 86px;
    z-index: 5;
  }
}

.opened {
  display: block;
}

.isOpen {
  height: 124px !important;
  margin-bottom: 140px;
  transition: 0.1s all linear;
}

.title {
  display: flex;
}

.icon {
  margin-left: 10px;
  transition: 0.1s all linear;
}
