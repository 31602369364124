@import "@root/theme/utils.scss";

.root {
  padding: 25px 30px 30px 30px;
}

.title {
  margin-bottom: 5px;
}

.container {
  display: flex;
  align-items: flex-end;
  margin-top: 16px;
  gap: $p24;

  &>* {
    flex: 0 1 230px
  }
}

.onEdit {
  margin-bottom: 100px;
}