@import "@root/theme/colors.scss"
@import "@root/theme/size.scss"

.root
  position: relative
  display: flex
  flex-direction: column
  align-items: stretch
  height: min(700px, 90vh)
  width: min(1200px, 90vw)
  box-sizing: border-box

.header
  display: flex
  flex-direction: row
  padding: $p24
  padding-bottom: $p16
  justify-content: space-between
  &:hover + .titleTooltip
    visibility: visible
    opacity: 1

.titleTooltip
  position: absolute
  visibility: hidden
  padding: 5px 7px
  border-radius: 5px
  left: 25px
  top: 70px
  background-color: $black03Color
  opacity: 0
  z-index: 5
  box-shadow: 1px 1px 10px -5px $black70Color
  transition: opacity 0.3s ease, visibility 0.3s ease

.body
  display: grid
  gap: $p20
  grid-template-columns: 1fr 400px
  align-items: stretch
  padding: $p24
  padding-top: 0
  overflow: auto
  flex: 1

.title
  display: flex
  padding: 10px
  border-radius: $p08
  border: 1px solid $black40Color
  gap: $p08
