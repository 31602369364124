
.root {
  display: flex;
  flex-direction: column;
  min-width: 1320px;
  padding: 60px 0;
  gap: 60px
}

.preview {
  display: flex;
  justify-content: space-between;
}

.footer {
  display: flex;
  justify-content: center;
}