$padding: 16px;

.root {
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

.header {
  display: block;
  padding: $padding;
}

.body {
  display: block;
  flex: 1 1 auto;
  white-space: pre-wrap;
  padding: 0 $padding $padding;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #212121;
  opacity: 0.7;
}

.footer {
  display: flex;
  gap: 15px;
  align-items: center;
  padding: $padding;
  border-top: 1px solid #2121211a;
  * {
    flex: 1 1 100%;
  }
}