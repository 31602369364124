.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 900px;
  max-width: 900px;
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 32px;
}

.body {
  flex: 1 1 100%;
  box-sizing: border-box;
  overflow: auto;
  margin-bottom: 22px;
}

.footer {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 32px;
  padding-top: 10px;
  width: 100%;
}

.control {
  display: flex;
  align-items: center;
  gap: 16px;
}

.changesBadge {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ff7f57;
}
