.root {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 16px;
  pointer-events: none;

  &.mobile {
    border-radius: 8px;
    line-height: 20px;

    .title {
      height: 20px;
      font-size: 12px;
    }
    .header {
      color: #1558d6;
      font-size: 18px;
      line-height: 24px;
      margin: 12px 0;
    }
  }

  &.desktop {
    line-height: 22px;

    .title {
      height: 22px;
      font-size: 14px;
    }
    .header {
      color: #1a0dab;
      display: flex;
      font-size: 20px;
      justify-content: space-between;
      line-height: 26px;
      margin: 3px 0;
      word-break: break-word;
    }
  }
}

.title {
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #202124;
}

.prefix {
  font-weight: 700;
  & > span {
    padding: 0 5px;
  }
}

.description {
  color: #3c4043;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  flex-grow: 1;
}

.arrowDown {
  color: #70757a;
  font-size: 0.8em;
  margin: 3px 3px 0 7px;
}

.info {
  color: #bdc1c6;
  margin-left: auto;
  padding-left: 5px;
}
