@import "@root/theme/colors.scss";

.root {
  position: relative;
  font-family: Poppins;
  user-select: none;
  color: black;
}

.closeIcon {
  font-size: 0.7em;
  position: absolute;
  top: 0;
  right: 0;
  margin: auto 0;
  height: 100%;
  padding: 0 15px;
  cursor: pointer;
  -webkit-filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.5));
}

.pdfImage {
  font-size: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto 0;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  z-index: 2;
  & > * {
    padding: 0 5px;
  }
}

.percent {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
