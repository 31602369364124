@import "@root/theme/utils.scss";

.mark {
  color: inherit;
  font-size: $p18;
}

.updated {
  color: $info100Color;
}

.deleted {
  color: $error100Color;
}

.hidden {
  visibility: hidden;
}