.root {
  text-align: center;
  min-width: 333px;

  & > * {
    margin-bottom: 15px;
  }
}

.control {
  margin: auto;
  padding-top: 30px;
  display: grid;
  gap: 5px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
