.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 400px;
  box-sizing: border-box;
}

.checkboxSection {
  height: fit-content;
}

.checkboxContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  & > *:not(.checkboxSection) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.divider {
  width: 100%;
  margin-top: 4.25rem;
  box-sizing: border-box;
}

.checkboxSection {
  padding: 0 0.875rem;
  flex-grow: 0;
}

.header {
  padding: 2rem;
  padding-bottom: 1.5rem;
}
.footer {
  box-shadow: 0px -4px 12px rgba(233, 236, 246, 0.8);
  padding: 1.5rem 2rem;
}
