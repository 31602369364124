@import "@root/theme/colors.scss";

.formContainer {
  padding: 8px 0;
  margin-block: 8px 12px;
}

.wrapContainer {
  padding-left: 24px;
  gap: 0;
}
