.root {
  width: 100%;
  height: auto;
}

.header {
  margin: 40px 15px 10px 15px;
}

.container {
  margin-top: 10px;
}
