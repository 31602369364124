.root {
  display: flex;
  align-items: stretch;
  & > * {
    height: auto;
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:not(:is(:first-child, :last-child)) {
      border-radius: 0;
    }
  }
  & > *:not(:last-child) {
    border-right-width: 1px;
    border-right-style: solid;
  }
  & > :not(*:first-child) {
    border-left: none;
  }
}
