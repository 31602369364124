@import "@root/theme/utils.scss";

.root {
  display: flex;
  flex-direction: column;
  overflow: auto;
  @include thinScrollbar()
}

.body {
  flex: 1 1 100%;
  overflow-y: auto;
  padding-right: 10px;
  margin-right: -10px;
}

.tableHeader {
  position: sticky;
  top: 0
}