@import "@root/theme/size.scss";

.size {
  &-small {
    --btn-padding-block: #{$p01};
    --btn-padding-inline: #{$p14};
  }
  
  &-medium {
    --btn-padding-block: #{$p07};
    --btn-padding-inline: #{$p14};
  }
  
  &-big {
    --btn-padding-block: #{$p09};
    --btn-padding-inline: #{$p14};
  }
}

