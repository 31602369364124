.root {
  margin-top: 74px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 74px;

  & > div:first-child {
    padding-bottom: 20px;
  }
}

.controls {
  display: flex;
  gap: 20px
}

.dropDownsWrapper {
  display: flex;
  justify-content: space-between;
  width: 382px;
  padding-bottom: 10px;
  column-gap: 10px;
  & > div {
    flex: 1 1 100%;
  }
}

.legendContainer {
  display: flex;
  margin-left: 20px;
  gap: 20px;
}

.chartBox {
  width: auto;
  padding: 24px;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(26, 115, 169, 0.1);
  border-radius: 10px;
  text-align: center;
}

