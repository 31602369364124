@import "@root/theme//colors.scss";

.root {
  display: flex;
  align-items: center;
  padding: 8px 19px 8px 8px;
  margin-bottom: 5px;
  overflow: visible;

  & > *:first-child {
    margin-right: 27px;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 30px;

  & > *:first-child {
    flex: 3 1;
  }
  & > *:last-child {
    flex: 0.5 1;
  }

  & > * {
    flex: 1 1;
  }
}

.clickable {
  cursor: pointer;
}

.contextMenu {
  position: absolute;
  top: 32px;
  right: 19px;
  max-width: 193px;
  z-index: 2;
}
