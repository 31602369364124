@import "@root/theme/colors.scss";

$gap: 25px;

.root {
  width: 100%;
}

.list {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
}

.row {
  display: flex;
  column-gap: 40px;
}

.column {
  flex: 1 1;
  padding: 0 5px;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
}

.text {
  overflow: inherit;
  white-space: inherit;
  text-overflow: inherit;
}

.widthBg {
  background-color: $pageBg;
  padding: 5px;
  border-radius: 10px;
}
