@import "@root/theme/utils.scss";

.tableBody {
  width: 100%;
  height: 500px;
  @include thinScrollbar('*');
}

.table {
  width: 100%;
  border-spacing: 0;
  table-layout: fixed;

  tr:first-child {
    z-index: 1;
  }

  th:first-child {
    width: 250px;
  }
}

.trend {
  justify-content: flex-start;
}

.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}

.content {
  padding-inline: $p24;
}

.dropdown {
  min-width: 280px;
}

.campaignRow {
  height: 100%;
  grid-template-columns: 1fr repeat(5, 135px);
}

.keywordRow {
  height: 100%;
  grid-template-columns: 1fr 120px repeat(5, 135px);
}

.productRow {
  height: 100%;
  grid-template-columns: 50px 1fr repeat(5, 135px);
}

.queryRow {
  height: 100%;
  grid-template-columns: 1fr 150px repeat(5, 135px);
}

.highlighted {
  background: $primary05Color;
}