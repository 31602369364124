.root {
}

.content {
  display: flex;
  flex-direction: row;
}

.chunk {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.title {
  height: 20px;
  margin-bottom: 5px;
}

.keyword {
}
