@import "@root/theme/colors.scss"
@import "@root/theme/size.scss"

.root
  padding: 24px
  width: 100%
  min-height: 200px
  overflow: auto
  border: 1px solid $primary20Color

.filed
  width: 200px
