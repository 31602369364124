@import "@root/theme/colors.scss";
@import "@root/theme/mixins.scss";

.root {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  width: 500px;

  @include thinScrollbar();
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 10px 25px 10px 20px;
  gap: 20px;
}

.list {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 100%;
  gap: 5px;
  padding: 5px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  cursor: pointer;
  padding: 0 15px;
  height: 60px;
  border: 1px solid $utilBorder;
  color: $grayTextColor;
  user-select: none;
}

.selected {
  border-color: $lightGreenColor;
}

.capitalize {
  text-transform: capitalize;
}

.item > *,
.header > * {
  flex: 1 1;
  display: flex;
  overflow: hidden;

  &:nth-child(1) {
    flex: 2 1;
  }
  &:nth-child(2) {
    flex: 1.5 1;
  }
  &:nth-child(3) {
    flex: 1 1;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  z-index: 0;
}
