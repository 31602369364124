@import "@root/theme/utils.scss";

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 700px;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding: 0 $p32;
  overflow: hidden;
}

.section {
  width: 100%;
}

.section:not(:last-child) {
  border-bottom: 1px solid $black20Color;
}

.scrollContainer {
  flex: 1 1;
  margin-inline: -$p18;
}

.bodyContainer {
  max-height: 100%;
  padding-inline: $p18;
  overflow-y: overlay;
}

.sectionBody {
  padding-block: $p10;
}

.row {
  display: grid;
  grid-template-columns: 95px 1fr;
}

.headlineRow {
  display: grid;
  grid-template-columns: 1fr 110px;
}

.descriptionRow {
  display: grid;
  grid-template-columns: 1fr;
}

.inputWithControl {
  display: grid;
  gap: $p08;
  grid-template-columns: 1fr auto;
  align-items: flex-start;
}

.refreshButton {
  font-size: $p18;
  padding: 9px;
} 

.spinner {
  height: 18px;
  width: 18px;
}

.colHeader {
  cursor: pointer;
}

.header {
  padding: $p32;
}

.body {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding: 0 $p32;
  overflow: hidden;
}

.footer {
  padding: $p24 $p32;
  box-shadow: 0px -4px 12px rgba(233, 236, 246, 0.8);
}
