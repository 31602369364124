@import "@root/theme/utils.scss";

.root {
  display: flex;
  flex-direction: column;
  padding: 0;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 16px;
}

.header {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 16px 24px;
  position: relative;
  font-size: 12px;
}
