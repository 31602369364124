.root {
  padding-inline: 24px;
  padding-block: 18px 38px;
}

.control {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  user-select: none;
}

.title {
  margin: 28px 0 17px 0;
}

.header {
  display: flex;
  column-gap: 20px;
  & > * {
    width: 220px;
  }
}

.rowWithLabel {
  display: flex;
  width: 100%;
  margin: 7px 0;
  align-items: center;
  & > *:first-child {
    width: 55px;
  }
  & > :not(:first-child) {
    flex: 1 1;
  }
}

.withBorder {
  border: 1px solid #c9c9c9;
  border-radius: 10px;
  min-height: 35px;
}

.row {
  display: flex;
  width: 100%;
}

.col {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 0 10px;
}

.button {
  max-width: 65px;
  max-height: 32px;
  padding: 5px !important;
  white-space: pre-wrap;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 108%;
}

.disabled {
  background: #f6f8ff !important;
  color: #868686 !important;
}

.date {
  padding-block: 4px;
}
