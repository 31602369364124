@import '@root/theme/utils.scss';

.root {
  display: flex;
  align-items: center;
  gap: $p08
}

.input {
  width: 200px;
}

.shortInput {
  box-sizing: border-box;
  width: 36px;
  padding-inline: $p08;
}