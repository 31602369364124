@import "@root/theme/colors.scss"
@import "@root/theme/size.scss"

.root
  width: 820px

.table
  width: 100%

  td
    box-sizing: border-box
    height: 60px

.justifyStart
  justify-content: flex-start
