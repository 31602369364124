@import "@root/theme/colors.scss";

.chartContainer {
  position: relative;
  flex: 1 1;
  max-height: 450px;
  height: 450px;
}

.chart {
  position: absolute;
  width: 100%;
  height: 100%;
}
