@import "@root/theme/colors.scss";

.root {
  position: relative;
  display: flex;
  justify-content: center;
}

.menuContainer {
  width: 100%;
  z-index: 2;
  top: 100%;
  right: 0;
  position: absolute;
}

.arrowIcon {
  font-size: 1em;
  margin: 0 0.5em;
}
