@import "@root/theme/colors.scss";

$gap: 16px;
$borderRadius: 10px;

.table {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.header {
  display: flex;
  padding: 0 15px;
  column-gap: $gap;
  flex-shrink: 1;
  padding-left: 55px;
}

.column {
  display: flex;
  overflow: hidden;
  text-transform: capitalize;
}

.column.headerColumn {
  cursor: pointer;
  user-select: none;
}

.scrollContainer {
  margin-top: 15px;
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 3px;
}

.scrollContainer::-webkit-scrollbar {
  width: 0.3em;
}

.scrollContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(95, 95, 95, 0.3);
}

.scrollContainer::-webkit-scrollbar-thumb {
  background: linear-gradient(
    90deg,
    $primaryLightColor 0%,
    lighten($primaryDarkColor, 5%) 100%
  );
}

.list {
  width: 100%;
  display: grid;
  gap: 10px;
}

.body {
  height: inherit;
  display: flex;
  column-gap: $gap;
  align-items: center;
}

.column.rowColumn {
  width: 100%;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.chartContainer {
  justify-self: center;
  max-width: 140px;
  height: 70px;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  z-index: 0;
}

.sortArrow {
  min-width: 15px;
  margin-left: 5px;
  display: flex;
  align-items: flex-start;
  margin-top: 3px;
}

.centered {
  text-align: center;
  margin-right: auto;
  width: 140px;
}

.shotInput {
  width: 100%
}

.rowFooter {
  padding: 14px 16px;
  & > *:first-child {
    white-space: nowrap;
  }
}

.keywordLabel {
  white-space: nowrap;
}