@import "@root/theme/colors.scss";

.root {
  width: 100%;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
