@import "@root/theme/colors.scss";

.root {
  font-size: 14px;
  line-height: 21px;
  padding: 8px 12px;
  color: $black100Color;
  user-select: none;
  cursor: pointer;

  &::marker {
    content: none;
  }
}

.title {
  font-weight: 800;
  font-size: 10px;
  line-height: 15px;
  color: $black40Color;
  margin-bottom: 10px;
}

.body {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.label {
  flex: 1 1 100%;
  white-space: pre-wrap;
}

.before,
.after {
  padding-inline: 5px 11px;
  i {
    font-size: 1.2em;
  }
}
.before {
  padding-inline: 5px 11px;
}
.after {
  padding-inline: 11px 5px;
}

.subtitle {
  color: $black60Color;
  opacity: 0.5;
  margin-top: 6px;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
}

.subItem {
  margin-left: 20px;
}

.root:hover {
  background-color: $primary10Color;
  color: $black80Color;
}

.root:active {
  background-color: $primary05Color;
  color: $black70Color;
}

.active {
  .input {
    border: none;
    border-radius: 10px 10px 0 0;
    border-color: $lightTextColor;
  }
  & > .wrapper {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  }
}

.root.disabled {
  pointer-events: none;
}

.root.displayDisabled {
  opacity: .5;
}
