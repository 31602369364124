@import '@root/theme/utils';

.listingGroupIcon {
    position: relative;
    display: none;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin-right: 25px;
    &.unitIncluded {
        display: block;
        background-color: $unitIncludedBgColor;
    }
    &.unitExcluded {
        display: block;
        background-color: $unitExcludedBgColor;
    }
    &.subdivision {
        display: block;
        background-color: $subdivisionBgColor;
        &:after {
            display: none;
        }
    }

    &:after {
        content: "";
        display: block;
        position: absolute;
        background-color: white;
        width: 7px;
        height: 3px;
        top: 6px;
        left: 4px;
    }
}

.tableWrap {
    width: 100%;
    padding: 0 0 60px;
}

.dataRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.table {
    box-sizing: border-box;
    min-width: 350px;
    tbody {
        box-sizing: border-box;
        td {
            border-bottom: 1px solid $black10Color;
        }
    }
    th {
        padding: 10px 10px;
        box-sizing: border-box;
        background-color: $black10Color;
    }
    td {
        padding: 10px 10px;
        box-sizing: border-box;
    }
}
