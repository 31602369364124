@import "@root/theme/colors.scss";

.root {
  width: 100%;
  position: relative;
  bottom: 0;
  z-index: 2;
}

.footer {
  position: fixed;
  width: 100%;
  height: 72px;
  bottom: 0;
  background: linear-gradient(
    180deg,
    $primaryLightColor 0%,
    $primaryDarkColor 100%
  );
}

.barContainer {
  display: flex;
  margin: 15px 60px 15px 120px;
  span {
    color: $lightTextColor;
  }
}

.column {
  flex: 1 1;
  padding-right: 20px;
}

.bar {
  margin-top: 10px;
}
